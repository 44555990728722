
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../Api/Api_Services/AuthService";
import { ProfileContext } from "../../Context";

const UserHeader = () => {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [coinList, setCoinList] = useState([]);
  const [coinList2, setCoinList2] = useState([]);
  const [searchPair, setSearchPair] = useState('');

  const { tradeTheme, setTradeTheme } = useContext(ProfileContext)

  // ********* Update Theme********** //
  const handleTheme = () => {
    const body = document.getElementById("body");
    let Theme = localStorage.getItem('theme-color');
    setTradeTheme(!tradeTheme)
    if (Theme === 'theme-light') {
      localStorage.setItem('theme-color', "theme-dark")
      body.classList.add('theme-dark');
      body.classList.remove("theme-light");
    } else {
      localStorage.setItem('theme-color', "theme-light")
      body.classList.add('theme-light');
      body.classList.remove("theme-dark");
    }
  };

  const ShowMobileSidebar = () => {
    let button = document.getElementById('qwert');
    button.classList.add("active");
  }
  const HideMobileSidebar = () => {
    let button = document.getElementById('qwert');
    button.classList.remove("active");
  }

  // ******* Seqarching pair Functianlity ******//
  const GetPairs = async () => {
    setloader(true);
    await AuthService.getPairs().then(async result => {
      if (result?.success) {
        setCoinList(result?.data)
        setCoinList2(result?.data)
      }
      setloader(false);
    });
  };

  const nextPage = (data) => {
    navigate(`/trade/${data?.base_currency}_${data?.quote_currency}`);
    window.location.reload();
  }

  const HandlePairSearch = (e) => {
    setSearchPair(e.target.value)
    let input = e.target.value?.toLowerCase();
    let filteredData = coinList2?.filter((item) => {
      return item?.base_currency?.toLowerCase()?.includes(input) || item?.quote_currency?.toLowerCase()?.includes(input)
    });
    setCoinList(filteredData)
  }


  // ******* Show/Hide Search Bar for Coins*******//

  const handleClick = (event) => {
    const button = document.getElementById('serachBar2')
    if (typeof (event.target.className) === 'string' && event.target.className.includes('serachBar')) {
      button.classList.add('show')
    } else {
      if (button) {
        button.classList.remove('show');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <>
      <header className="ib-header header-default header-fixed header--sticky fluid-header">
        <div className="container-fluid">
          <div className="header-inner d-flex align-items-center justify-content-between">
            <div className="header-left d-flex align-items-center">
              <div className="logo-wrapper  ">
                <Link to="/" className="logo logo-light">
                  <img src="/images/logo-white.svg" alt="brand" />
                </Link>
                <Link to="/" className="logo logo-dark">
                  <img src="/images/logo-white.svg" alt="brand" />
                </Link>
              </div>
              {/* <div className="logo-wrapper d-block d-lg-none ">
                <Link to="/" className="logo ">
                  <img src="/images/favicon/favicon.png" alt="brand" />
                </Link>
              </div> */}
              <div className="mainmenu-wrapper ms-md-3">
                <nav id="sideNav" className="mainmenu-nav d-none d-xl-block">
                  <ul className="mainmenu">
                    <li>
                      <Link to="/" >
                        Home  
                      </Link>
                    </li>
                    <li className="custom_dropdown" >

                      <Link to="/MarketPage">Buy Crypto <i className="ri-arrow-down-s-line ms-1"></i> </Link>

                      <ul className="submenu">
                        <li>
                          <Link to="/BuySell">
                            <div className="d-flex  align-items-center" >
                              <i className="ri-arrow-up-down-line me-4"></i>
                              <div>
                                <span>Quick Buy/Sell</span>
                                <small>Fast and secure digital asset transactions</small>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/comingsoon">
                            <div className="d-flex  align-items-center" >
                              <i className="ri-group-fill me-4"></i>
                              <div>
                                <span>P2P Trading</span>
                                <small>Bank transfer and more options</small>
                              </div>
                            </div>
                          </Link>
                        </li>
                      </ul>

                    </li>
                    <li >
                      <Link to="/MarketPage" >
                        Market
                      </Link>
                    </li>
                    {/* <li><a href="/trade/Header">Trade </a></li> */}

                    <li className="custom_dropdown" >

                      <Link to="/trade/Header">Trade   <i className="ri-arrow-down-s-line ms-1"></i> </Link>

                      <ul className="submenu">
                        <li>
                          <Link to="/trade/Header" >
                            <div className="d-flex align-items-center" >
                              <i className="ri-line-chart-line me-4"></i>
                              <div>
                                <span> Trade </span>
                                <small>  Trade on CTEX platform </small>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/Launchpad" >
                            <div className="d-flex align-items-center" >
                              <i className="ri-rocket-fill me-4"></i>
                              <div>
                                <span>Launchpad </span>
                                <small> Hold CTEX and Earn New Token </small>
                              </div>
                            </div>
                          </Link>
                        </li>
                      </ul>

                    </li>

                    <li>
                      <Link to="/Staking" >
                        Staking
                      </Link>
                    </li>
                    <li >
                      <Link to="/FundPage" >
                        Wallet
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="header-right d-flex align-items-center">
              <ul className="header-right-inner ">
                {/* <li className="header_search  d-none d-md-block mx-0 " >
                  <div className="searchBar custom-tabs mt-0" onClick={() => { GetPairs() }}>
                    <i className="ri-search-2-line serachBar"></i><input type="search" className="custom_search serachBar" placeholder="Search" onChange={HandlePairSearch} value={searchPair} />
                  </div>

                  <div className={`search_result dropdown-menu scroll_y serachBar `} id="serachBar2"  >
                    <div className="search_tabs serachBar" >
                      <div className=" trade_tabs buy_sell_cards py-0  serachBar">
                        <ul className="nav custom-tabs nav_order scroll_x justify-content-between serachBar">
                          <li className="all-tab serachBar">
                            <a className="active serachBar" data-bs-toggle="tab" href="#topSearches">Top Searches </a>
                          </li>
                          <li className="all-tab serachBar" onClick={() => { setSearchPair(''); }}>
                            <a className="serachBar" data-bs-toggle="tabs" href="#hotEvents" ><i className="ri-close-line " ></i> </a>
                          </li>
                        </ul>

                      </div>
                      <div className="tab-content serachBar" >
                        <div className="tab-pane px-0 active serachBar" id="topSearches">
                          {(coinList?.length > 0 > 0 && !loader) ? coinList?.map((item, index) => {
                            return (
                              <div key={item?._id} className='markets_row__xELC_ markets_rowItem__RPIFi markets_rowActive__NlFL8 serachBar' onClick={() => nextPage(item)} >
                                <span className="ln active serachBar" >{index + 1}</span>
                                <div className="markets_col1__kEdFH ps-1 serachBar">
                                  <div className="markets_symbol__gj7q4 serachBar">
                                    <div><span className="markets_strong__s_Hxk serachBar"></span><span>{item?.base_currency}/{item?.quote_currency}</span></div>
                                  </div>
                                  <div className="markets_secondLine__vuaeq serachBar"><span>{item?.name}</span></div>
                                </div>
                                <div className="markets_col2__RMpcV serachBar">
                                  <div className="markets_strong__s_Hxk serachBar">{item?.buy_price}</div>
                                  <div className="markets_secondLine__vuaeq serachBar"><span className=""><span dir="ltr"><span>$</span><span dir="ltr">{item?.buy_price}</span></span>
                                  </span>
                                  </div>
                                </div>
                                <div className="markets_col3__LCAVs serachBar">
                                  <div className={`markets_change__K2rx5  serachBar ${item?.change_percentage >= 0 ? 'text-success' : 'text-danger'}`}>{item?.change_percentage}%</div>
                                  <div className="markets_secondLine__vuaeq serachBar">{item?.volume}</div>
                                </div>
                              </div>
                            )
                          }) : loader ? <div className="text-center no-data h-100 mb-0" >
                            <div className="loading-wave">
                              <div className="loading-bar"></div>
                              <div className="loading-bar"></div>
                              <div className="loading-bar"></div>
                              <div className="loading-bar"></div>
                            </div>
                          </div> : <div className="text-center no-data h-100 mb-0 serachBar" >
                            <div className="loading-wave serachBar"> <p className="text-center serachBar" style={{ textAlign: "center" }}>
                              No data Available
                            </p></div>
                          </div>}

                        </div>
                      </div>

                    </div>
                  </div>
                </li> */}
                <li className="wallet-button">
                  <Link to="/login" className="btn btn-link text-white">
                    <span>Log In</span>
                  </Link>
                  <div className="btn-group">
                    <Link to="/signup" className="btn btn-gradient btn-small">
                      <span>Sign Up</span>
                    </Link>
                  </div>
                </li>
                <li className="setting-option mobile-menu-bar d-block d-xl-none ">
                  <button className="hamberger-button" type="button" onClick={ShowMobileSidebar}>
                    <i className="ri-menu-2-fill"></i>
                  </button>
                </li>

                {/* <li className="avatar-info mx-2" >
                  <span className="cursor_pointer">
                    <label className="theme-switcher-label d-flex active" >
                      <div className="switch-handle">
                        <i className="ri-global-line"></i>
                      </div>
                    </label>
                  </span>
                  <ul className="submenu py-0 p-0 no-border" >
                    <div id="google_elemet"></div>
                  </ul>
                </li> */}
                <label className="theme-switcher-label d-flex active" htmlFor="theme-switcher"                >
                  <input type="checkbox" className="theme-switcher" id="theme-switcher" onClick={handleTheme} />
                  <div className="switch-handle">
                    <i className="ri-moon-line dark-text"></i>
                    <i className="ri-sun-line light-text"></i>
                  </div>
                </label>

              </ul>
            </div>
          </div>
        </div>
      </header>


      {/* <!-- Start mobile menu area --> */}
      <div className="popup-mobile-menu" id="qwert" >
        <div className="inner">
          <div className="header-top">
            <div className="logo logo-custom-css">
              <a className="logo-light" href="/">
                <img src="/images/logo-white.svg" alt="nft-logo" />
              </a>
              <Link className="logo-dark" to="/">
                <img src="/images/logo-white.svg" alt="nft-logo" />
              </Link>
            </div>
            <div className="close-menu">
              <button className="close-button" type="button" onClick={HideMobileSidebar}>
                <i className="ri-close-fill"></i>
              </button>
            </div>
          </div>
          <nav>
            <ul className="mainmenu  accordion" id="accordionExample" >
              <li><Link to="/" onClick={() => { HideMobileSidebar() }}> Home </Link> </li>

              <li className="mobile_dropwdown accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button p-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Buy Crypto
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse  " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <ul className="submenu">

                    <li>
                      <Link to="/BuySell" onClick={() => { HideMobileSidebar() }}>
                        <span>Quick Buy/Sell</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/comingsoon" onClick={() => { HideMobileSidebar() }}>
                        <span>P2P Trading</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li >
                <Link to="/MarketPage" onClick={() => { HideMobileSidebar() }}>
                  Market
                </Link>
              </li>
              {/* <li><a href="/trade/Header">Trade </a></li> */}


              <li className="mobile_dropwdown accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button p-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Trade
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse  " aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <ul className="submenu">

                    <li>
                      <Link to="/trade/header" onClick={() => { HideMobileSidebar() }}>
                        <span> Trade  </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/launchpad" onClick={() => { HideMobileSidebar() }}>
                        <span>Launchpad</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <Link to="/Staking" onClick={() => { HideMobileSidebar() }}>
                  Staking
                </Link>
              </li>
              <li >
                <Link to="/FundPage" onClick={() => { HideMobileSidebar() }}>
                  Wallet
                </Link>
              </li>
              {/* <li >
                <a href='https://api.ctexexchange.com/downloads'>
                  Download App
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
