import React, { useState, useEffect, useContext } from "react";
import AuthService from "../../Api/Api_Services/AuthService";
import { alertErrorMessage } from "../../Utils/CustomAlertMessage";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import { ProfileContext } from "../../Context";

const ReferralList = () => {
  const [refferralData, setRefferralData] = useState([]);
  const { ctexPrice } = useContext(ProfileContext)

  useEffect(() => {
    handleReferralLink();
  }, []);

  const handleReferralLink = async () => {
    await AuthService.referralLink().then(async (result) => {
      if (result?.success) {
        refferralList(result?.data);
      }
    });
  };


  const refferralList = async (code) => {
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.userReferralList(code)
      if (result?.success) {
        setRefferralData(result?.data);
      }
    } catch (error) {
      alertErrorMessage("Something went wrong")
    }
  };

  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title text-gradient">
              Referal List
            </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/ReferralPage">Referral Program</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Referral List
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="d-flex-between mb-5">
            {/* <ul className="nav custom-tabs">
              <li><a className=" active" data-bs-toggle="tab" href="#tt_history"> Referred Friends</a> </li>
            </ul> */}
          </div>
          <div className="tab-content custom-tab-content p-0">
            <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tt_history">
              <div className="table-responsive">
                <table className="table ">
                  <thead style={{ backgroundColor: "#252f30" }} >
                    <tr>
                      <th>Sr No.</th>
                      <th>Date/Time</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Reward</th>
                    </tr>
                  </thead>
                  <tbody>
                    {refferralData.length > 0 ?
                      refferralData.map((item, index) => (
                        <tr key={index} >
                          <td className="color-grey">{index + 1}</td>
                          <td className="color-grey">{<Moment date={item?.createdAt} format="DD/MM/ YYYY" />}</td>
                          <td className="color-grey">{item?.name}</td>
                          <td className="color-grey">{item?.emailId}</td>
                          <td className="color-grey">{item?.ctex_reward == "true" ? ctexPrice * 2 : 0} CTEX</td>
                        </tr>
                      ))
                      :
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferralList;
