import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { alertSuccessMessage, alertWarningMessage } from "../../Utils/CustomAlertMessage";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import { CountryDetails } from "../../Utils/CountryList";
import DefaultInput from "../../Utils/DefaultInput";
import { validatePanCard, matchPanCard, drivingLicense, documentNum, postCode, notEqualsZero, aadharNum, email, matchDocument } from "../../Utils/Validation";
import { ProfileContext } from "../../Context";
import imageCompression from 'browser-image-compression';

const KycPage = () => {
  const { kycStatus, countryCode, setCountryCode } = useContext(ProfileContext);
  const [kycVerfied, setKycVerified] = useState(kycStatus);
  const [infoCountry, setInfoCountry] = useState("India");
  const [kycType, setKycType] = useState("Personal");
  const [number, setNumber] = useState("");
  const [isNumbExist, setIsNumbExist] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState(" ");
  const [lastName, setLastName] = useState("");
  const [infoDob, setInfoDob] = useState("");
  const [docType, setDocType] = useState("");
  const [address, setAddress] = useState("");
  const [infoState, setInfoState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  // const [countryCode, setCountryCode] = useState("91:India");
  const [aadhar, setAadhar] = useState("");
  const [localFront, setLocalFront] = useState("");
  const [localBack, setLocalBack] = useState("");
  const [localSelfie, setLocalSelfie] = useState("");
  const [panCard, setPanCard] = useState("");
  const [rePanCard, setRePanCard] = useState("");
  const [localPanCard, setLocalPanCard] = useState("");
  const [kyc2fa, setKyc2fa] = useState('');
  const [emailId, setEmailId] = useState("");
  const [gender, setGender] = useState("male");
  const [reason, setReason] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [selfieImage, setSelfieImage] = useState("");
  const [pancardImage, setPanCardImage] = useState("");
  const [isShowOther, setIsShowOther] = useState();
  const [resendOtp, setResendOtp] = useState(false);
  const [resendOtp2, setResendOtp2] = useState(false);
  const [mobileOtp, setmobileOtp] = useState('');
  const [emailOtp, setemailOtp] = useState('');
  const [isShow, setIsShow] = useState();
  const [emailError, setemailError] = useState(false);

  const CheckValidImage = (event) => {
    if (!event.target.files[0]) {
      return false;
    }
    if (event.target.files[0].size <= 5000000 && (event.target.files[0].type === "image/png" || event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/jpg")) {
      return true
    } else {
      alertWarningMessage('Only JPEG, PNG & JPG formats and file size upto 5MB are supported')
    }
  };

  const CompressFile = async (file) => {
    try {
      LoaderHelper.loaderStatus(true)
      const options = {
        maxSizeMB: 2.5,
      }
      const compressedFile = await imageCompression(file, options);
      const updatedFileFormat = new File([compressedFile], '1 (1).jpg', {
        type: 'image/jpeg',
        lastModified: 1714122028211
      });
      LoaderHelper.loaderStatus(false)
      return updatedFileFormat
    } catch (error) {
      LoaderHelper.loaderStatus(false)
    }
  }

  const handleChangeIdentity = async (event) => {
    event.preventDefault();
    let ImageValidation = CheckValidImage(event);
    if (ImageValidation) {
      let CompressedFile = await CompressFile(event.target.files[0])
      setLocalFront(CompressedFile);
      const imgata = URL.createObjectURL(event.target.files[0]);
      setFrontImage(imgata);
    }
    else {
      setLocalFront();
      setFrontImage();
    }
  };

  const handleChangeIdentity2 = async (event) => {
    event.preventDefault();
    let ImageValidation = CheckValidImage(event);
    if (ImageValidation) {
      let CompressedFile = await CompressFile(event.target.files[0])
      setLocalBack(CompressedFile);
      const imgata = URL.createObjectURL(event.target.files[0]);
      setBackImage(imgata);
    }
    else {
      setLocalBack();
      setBackImage();
    }
  };

  const handleChangeSelfie = async (event) => {
    event.preventDefault();
    let ImageValidation = CheckValidImage(event);
    if (ImageValidation) {
      let CompressedFile = await CompressFile(event.target.files[0])
      setLocalSelfie(CompressedFile);
      const imgata = URL.createObjectURL(event.target.files[0]);
      setSelfieImage(imgata);
    }
    else {
      setLocalSelfie();
      setSelfieImage();
    }
  };

  const handleChangePanCard = async (event) => {
    event.preventDefault();
    let ImageValidation = CheckValidImage(event);
    if (ImageValidation) {
      let CompressedFile = await CompressFile(event.target.files[0])
      setLocalPanCard(CompressedFile);
      const imgata = URL.createObjectURL(event.target.files[0]);
      setPanCardImage(imgata);
    }
    else {
      setLocalPanCard();
      setPanCardImage();
    }
  };

  const handleKyc = async (infoCountry, kycType, firstName, lastName, gender, number, infoDob, address, infoState, city,
    zipCode, aadhar, panCard, localFront, localBack, localSelfie, localPanCard, docType, rePanCard, middleName, mobileOtp, emailOtp, emailId) => {
    if (infoCountry === "India") {
      if (isShow === 1 && aadharNum(aadhar) !== undefined) {
        alertWarningMessage('Please enter valid Aadhar Number')
        return
      } else if (isShow === 2 && drivingLicense(aadhar) !== undefined) {
        alertWarningMessage('Please enter valid DL Number')
        return
      } else if (isShow === 3 && documentNum(aadhar) !== undefined) {
        alertWarningMessage('Please enter valid Document Number')
        return
      } else if (validatePanCard(panCard) !== undefined) {
        alertWarningMessage('Please enter valid PAN')
        return
      } else if (panCard !== rePanCard) {
        alertWarningMessage('PAN Number does not Match')
        return
      }
    }
    if (infoCountry !== "India") {
      if (panCard !== rePanCard) {
        alertWarningMessage('Document Number does not Match')
        return
      }
    }
    var formData = new FormData();
    formData.append("document_front_image", localFront);
    formData.append("document_back_image", localBack);
    formData.append("user_selfie", localSelfie);
    formData.append("pancard_image", localPanCard);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", infoState);
    formData.append("country", infoCountry);
    formData.append("document_number", aadhar);
    formData.append("pancard_number", panCard);
    formData.append("confirm_pancard_number", rePanCard);
    formData.append("dob", infoDob);
    formData.append("zip_code", zipCode);
    formData.append("first_name", firstName);
    formData.append("middle_name", middleName);
    formData.append("last_name", lastName);
    formData.append("kyc_type", kycType);
    formData.append("gender", gender);
    // formData.append("mobileNumber", number);
    formData.append("document_type", docType);
    // formData.append("motp", mobileOtp);
    formData.append("eotp", emailOtp);
    formData.append("emailId", emailId);

    LoaderHelper.loaderStatus(true);
    await AuthService.addkyc(formData).then(async (result) => {
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleResetInput();
        handleDetials();
      }
    });
  };

  const handleResetInput = () => {
    setInfoCountry("");
    setKycType("");
    setMiddleName("");
    setFirstName("");
    setLastName("");
    setInfoDob("");
    setAddress("");
    setInfoState("");
    setCity("");
    setZipCode("");
    setAadhar("");
    setLocalFront();
    setLocalBack();
    setLocalSelfie();
    setPanCard("");
    setRePanCard("");
    setLocalPanCard();
  };

  useEffect(() => {
    handleDetials();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleDetials = async () => {
    await AuthService.getDetails().then(async (result) => {
      if (result?.success) {
        setKycVerified(result?.data?.kycVerified);
        setKyc2fa(result?.data?.["2fa"]);
        setEmailId(result?.data?.emailId);
        setFirstName(result?.data?.firstName);
        setLastName(result?.data?.lastName);
        setReason(result?.data?.kyc_reject_reason);
        setNumber(result?.data?.mobileNumber);
        if (result?.data?.mobileNumber) {
          setIsNumbExist(true)
        } else {
          setIsNumbExist(false)
        }
      }
    });
  };

  const handleSelected = (type) => {
    setIsShow(type);
  };

  const handleSelectedOther = (type) => {
    setIsShowOther(type);
  };

  const getEighteenYearsAgoDate = () => {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );
    eighteenYearsAgo = moment(eighteenYearsAgo).format("YYYY-MM-DD");
    return eighteenYearsAgo;
  };

  const verifyAgain = () => {
    setKycVerified("4");
  };

  useEffect(() => {
    let filteredCountry = CountryDetails?.filter((data) => data?.label === infoCountry);
    setCountryCode(`${filteredCountry[0]?.phone}:${filteredCountry[0]?.label}`)
  }, [infoCountry]);

  const handleGetOtp = async (signId, type) => {
    if (type === 'phone') {
      const [code, label] = countryCode.split(':');
      let filteredCountry = CountryDetails?.filter((data) => data?.phone === code && data?.label === label)?.map((item) => item?.phoneLength);
      if (signId?.length !== filteredCountry[0]) {
        alertWarningMessage(`Please enter valid phone number for ${label}`)
        return
      }
    };
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId).then(async (result) => {
      if (result?.success) {
        if (type === 'phone') {
          setResendOtp(true)
        } else {
          setResendOtp2(true)
        }
        alertSuccessMessage(result?.message);
      }
    });
  };

  useEffect(() => {
    if (email(emailId) === undefined) {
      setemailError(false)
    }
  }, [emailId]);
  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title text-white">KYC Verification</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/profile/Settings">My Account</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  KYC Verification
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-90">
        {(kycVerfied === 1 || kycVerfied === 2 || kycVerfied === 3) ?
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="create-item-wrapper create-item-wrapper-kyc">
                  <div className="form-field-wrapper kyc_wrapper ">
                    <div className="rightsidebox">
                      <div className="kyc_nofti kyc_done">
                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                          <div className={!emailId ? 'check_bar' : 'check_bar acive'}>
                            <i className="ri-check-fill"></i>
                            <h5> Email</h5>
                          </div>
                          <div
                            className={kyc2fa === 0 ? 'check_bar' : 'check_bar acive'}>
                            <i className="ri-check-fill"></i>
                            <h5> Security </h5>
                          </div>
                          <div className={kycVerfied === 2 ? "check_bar acive" : "check_bar"}>
                            <i className="ri-check-fill"></i>
                            <h5> Welcome </h5>
                          </div>
                        </div>
                        <hr />
                        {kycVerfied === 1 && <div className="d-kyc_sec"><div><h5 className="text-warning">Your CTEX account Kyc is Pending</h5></div></div>}
                        {kycVerfied === 2 && <div className="d-kyc_sec"><div><h4 className="text-success pb-2">Congratulations</h4>
                          <p>Your CTEX account Kyc is Approved</p>
                          </div>
                          <Link to="/trade/Kyc" className="btn btn-gradient btn-medium justify-content-center">
                            <span>Start Trading</span>
                          </Link>
                        </div>}
                        {kycVerfied === 3 && <div className="d-kyc_sec"><div><h5>Your CTEX account Kyc is Rejected</h5>
                          <span className="text-danger mt-3">{reason}</span></div>
                          <button className="btn btn-gradient btn-medium justify-content-center" onClick={verifyAgain}><span>Verify Again</span></button></div>}
                        <hr />
                        <div className="d-kyc_share">
                          <div>
                            <h5 className="text-white">
                              Earn upto 50% commssion on your friend's trading fees
                            </h5>
                            <p>
                              <small>
                                when your friend sign up using your referal link
                                & trades, you can earn 50% commission on their
                                trading fees.
                              </small>
                            </p>
                          </div>
                          <Link to="/ReferralPage" className="btn btn-dark btn-mini">
                            <span>Invite & Earn</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="container">
            <form onSubmit={(e) => {
              e.preventDefault();
              handleKyc(infoCountry, kycType, firstName, lastName, gender, number, infoDob, address, infoState, city,
                zipCode, aadhar, panCard, localFront, localBack, localSelfie, localPanCard, docType, rePanCard, middleName, mobileOtp, emailOtp, emailId)
            }}>
              <div className="row">
                <div className="col-lg-10 m-auto">
                  <div className="create-item-wrapper">
                    <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="royality" className="form-label">
                              Select country
                            </label>
                            <select
                              className=""
                              id="inputGroupSelect03"
                              value={infoCountry}
                              name="infoCountry"
                              onChange={(event) => { setDocType(''); setAadhar(""); setInfoCountry(event.target.value) }}>
                              {CountryDetails?.map(value => {
                                return <option value={value?.label} key={value?.label}>
                                  {value?.label}
                                </option>
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="sizes" className="form-label">
                              KYC Type
                            </label>
                            <input type="text" value={kycType} name="kycType" className="form-control" onChange={(event) => setKycType(event.target.value)} disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 pt-6">
                      <h5 className="title ">Personal Information</h5>
                    </div>
                    <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              First Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={firstName === "undefined" ? "" : firstName}
                              name="firstName" onChange={(event) => setFirstName(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Middle Name
                            </label>
                            <input type="text" placeholder="" value={middleName === "undefined" ? "" : middleName} name="middleName" onChange={(event) => setMiddleName(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Last Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={lastName === "undefined" ? "" : lastName}
                              name="lastName" onChange={(event) => setLastName(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="pb-2" htmlFor="">
                              Gender<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="d-flex align-items-center mb-3">
                              <div className="form-check me-3">
                                <input id="male" className="form-check-input" type="radio" name="gender" value="male" onClick={(event) => setGender(event.target.value)} checked={gender === 'male'} readOnly />
                                <label className="form-check-label ms-2" htmlFor="male">
                                  Male
                                </label>
                              </div>
                              <div className="form-check">
                                <input id="female" className="form-check-input" type="radio" value="female" name="gender" onClick={(event) => setGender(event.target.value)} checked={gender === 'female'} readOnly />
                                <label className="form-check-label ms-2" htmlFor="female">
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                        {/* <div className="col-12 ">
                          <div className="field-box field-otp-box">
                            <label htmlFor="Code" className="form-label">
                              Mobile Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div> */}
                        {/* <div className="row">
                          <div className="col-3 mb-4">
                            <div className="field-box ">
                              <select name="countryCode" id="" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} disabled>
                                <optgroup >
                                  {CountryDetails?.map(value => {
                                    return <option value={`${value?.phone}:${value?.label}`} key={`${value?.phone}:${value?.label}`}>
                                      {`${value?.label}  (+${value?.phone})`}
                                    </option>
                                  })}
                                </optgroup>
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-4">
                            <div className="field-box field-otp-box">
                              <DefaultInput required type="number" name="mobile" placeholder="Enter mobile number" value={number} disabled={isNumbExist}
                                onChange={(e) => setNumber(e.target.value)} onWheel={(e) => { e.target.blur() }} />
                              <button type="button" className="btn btn-sm btn-gradient" onClick={() => handleGetOtp(number, 'phone')} >
                                <span>{resendOtp ? 'Resend OTP' : 'GET OTP'}</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-3 mb-3">
                            <div className="field-box">
                              <input type="number" placeholder="Enter Mobile OTP" onWheel={(e) => { e.target.blur() }} value={mobileOtp} onChange={(e) => { setmobileOtp(e.target.value) }} />
                            </div>
                          </div>
                        </div> */}
                        <div className="col-12">
                          <div className="fleld-box">
                            <label htmlFor="email" className="form-label">
                              Email Id
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-8 mb-4">
                          <div className="field-box field-otp-box">
                            <DefaultInput disabled required errorstatus={(email(emailId)) ? 'true' : 'false'} errormessage={emailError ? email(emailId) : null}
                              name="email" type="email" placeholder="Enter Email" value={emailId}
                            //  onChange={(e) => setEmailId(e.target.value)} 
                            />
                            <button type="button" className="btn btn-sm btn-gradient" onClick={() => { email(emailId) === undefined ? handleGetOtp(emailId, 'email') : setemailError(true); }}>
                              <span> {resendOtp2 ? 'Resend OTP' : 'GET OTP'} </span>
                            </button>
                          </div>
                        </div>
                        <div className="col-4 mb-3">
                          <div className="field-box">
                            <input id="emailOTP" name="emailOTP" type="number" placeholder="Enter Email OTP" value={emailOtp} onChange={(e) => { setemailOtp(e.target.value) }} onWheel={(e) => { e.target.blur() }} />
                          </div>
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label htmlFor="dob" className="form-label">
                              Date of birth
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input name="dob" id="dob" type="date" style={{ cursor: "pointer" }} required placeholder="ddd" max={getEighteenYearsAgoDate()} value={infoDob} onChange={(event) => setInfoDob(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Pin Code <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput required errorstatus={(postCode(zipCode)) ? 'true' : 'false'} errormessage={postCode(zipCode)} type="number" value={zipCode} name="zipCode" onChange={(event) => setZipCode(event.target.value)} onWheel={(e) => { e.target.blur() }} />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              City<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={city} name="city" onChange={(event) => setCity(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              State<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={infoState} name="state" onChange={(event) => setInfoState(event.target.value)} />
                          </div>
                        </div>

                        <div className="col-md-12 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Address<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" required placeholder="" value={address} name="address" onChange={(event) =>
                              setAddress(event.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 pt-6">
                      <h5 className="title ">Document Type</h5>
                    </div>

                    {infoCountry === "India" ? <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="sizes" className="form-label">
                              Select Document Type <span style={{ color: "red" }}>*</span>
                            </label>
                            <select value={docType} required name="docType"
                              onChange={(event) => {
                                setAadhar(""); setDocType(event.target.value);
                                handleSelected(event.target.value === "Aadhaar" ? 1 : event.target.value === "Driving License" ? 2 : event.target.value === "Passport" ? 3 : undefined)
                              }}>
                              <option value="" defaultValue disabled>Select</option>
                              <option value="Aadhaar">Aadhar card </option>
                              <option value="Driving License">
                                Driving License
                              </option>
                              <option value="Passport">Passport</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className={`field-box ${isShow !== 1 && "d-none"}`} id="aadhar">
                            <label htmlFor="name" className="form-label">
                              Aadhar Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              errorstatus={(aadharNum(aadhar) !== undefined && notEqualsZero(aadhar)) ? 'true' : 'false'}
                              errormessage={aadharNum(aadhar)}
                              onChange={(event) => setAadhar(event.target.value)} />
                          </div>
                          <div className={`field-box ${isShow !== 2 && "d-none"}`} >
                            <label htmlFor="name" className="form-label">
                              Driving License Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              errorstatus={(drivingLicense(aadhar) !== undefined && notEqualsZero(aadhar)) ? 'true' : 'false'}
                              errormessage={drivingLicense(aadhar)}
                              onChange={(event) => setAadhar(event.target.value.toUpperCase())} />
                          </div>
                          <div className={`field-box ${isShow !== 3 && "d-none"}`}>
                            <label htmlFor="name" className="form-label">
                            Passport Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              errorstatus={(documentNum(aadhar) !== undefined && notEqualsZero(aadhar)) ? 'true' : 'false'}
                              errormessage={documentNum(aadhar)}
                              onChange={(event) => setAadhar(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Front Image<span style={{ color: "red" }}>*</span>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Only JPEG, PNG & JPG formats are supported)
                              </div>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Max image size is 5MB)
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" data-multiple-caption="{count} files selected"
                              onChange={handleChangeIdentity} />
                            {docType === "" ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </label>
                              :
                              <label htmlFor="file" title="No File Choosen">
                                <img alt="" src={docType === 'Aadhaar' && !frontImage ? '/images/kyc/aadhaar_front.png' : docType === 'Driving License' && !frontImage ? '/images/kyc/dl front.png' : docType === 'Passport' && !frontImage ? '/images/kyc/passport_front.png' : frontImage} className="img-fluid" width="300" height="300" />
                              </label>}
                          </div>
                        </div>
                        <div className="col-md-6 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Back Image <span style={{ color: "red" }}>*</span>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Only JPEG, PNG & JPG formats are supported)
                              </div>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Max image size is 5MB)
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" onChange={handleChangeIdentity2} />
                            {docType === "" ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </label>
                              :
                              <label htmlFor="file" title="No File Choosen">
                                <img alt="" src={docType === 'Aadhaar' && !backImage ? '/images/kyc/aadhaar_back.png' : docType === 'Driving License' && !backImage ? '/images/kyc/dl back.png' : docType === 'Passport' && !backImage ? '/images/kyc/passport_back.png' : backImage} className="img-fluid" width="300" height="300" />
                              </label>}
                          </div>
                        </div>
                      </div>
                    </div> : <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="sizes" className="form-label">
                              Select Document Type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select required value={docType} name="docType"
                              onChange={(event) => {
                                setAadhar(""); setDocType(event.target.value);
                                handleSelectedOther(event.target.value === "Driving License" ? 2 : event.target.value === "Passport" ? 3 : undefined)
                              }}>
                              <option value="" disabled>Select</option>
                              <option value="Driving License">
                                Driving License
                              </option>
                              <option value="Passport">Passport</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className={`field-box ${isShowOther !== 2 && "d-none"}`}>
                            <label htmlFor="name" className="form-label">
                              Driving License Number <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              onChange={(event) => setAadhar(event.target.value.toUpperCase())} />
                          </div>
                          <div className={`field-box ${isShowOther !== 3 && "d-none"}`}>
                            <label htmlFor="name" className="form-label">
                              Passport Number <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="aadhar" value={aadhar}
                              onChange={(event) => setAadhar(event.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Front Image<span style={{ color: "red" }}>*</span>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Only JPEG, PNG & JPG formats are supported)
                              </div>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Max image size is 5MB)
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" data-multiple-caption="{count} files selected" onChange={handleChangeIdentity} />
                            {docType === '' ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </label>
                              : <label htmlFor="file" title="No File Choosen">
                                <img alt="" src={docType === 'Driving License' && !frontImage ? '/images/kyc/dl front.png' : docType === 'Passport' && !frontImage ? '/images/kyc/passport_front.png' : frontImage} className="img-fluid" width="300" height="300" />
                              </label>}
                          </div>
                        </div>
                        <div className="col-md-6 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Back Image <span style={{ color: "red" }}>*</span>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Only JPEG, PNG & JPG formats are supported)
                              </div>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Max image size is 5MB)
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" onChange={handleChangeIdentity2} />
                            {docType === '' ?
                              <label htmlFor="file" title="No File Choosen">
                                <i className="ri-upload-cloud-line"></i>
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </label> :
                              <label htmlFor="file" title="No File Choosen">
                                <img alt="" src={docType === 'Driving License' && !backImage ? '/images/kyc/dl back.png' : docType === 'Passport' && !backImage ? '/images/kyc/passport_back.png' : backImage} className="img-fluid" width="300" height="300" />
                              </label>}
                          </div>
                        </div>
                      </div>
                    </div>}
                    <div className="mb-4 pt-6">
                      {infoCountry === "India" ? <h5 className="title ">Pan Card</h5> : <h5 className="title ">Other Document</h5>}
                    </div>
                    {infoCountry === "India" ? <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Pan Card Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="panCard" value={panCard}
                              errorstatus={(validatePanCard(panCard) !== undefined && notEqualsZero(panCard)) ? 'true' : 'false'}
                              errormessage={validatePanCard(panCard)}
                              onChange={(event) => setPanCard(event.target.value.toUpperCase())} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Confirm Pan Card Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="rePanCard" value={rePanCard}
                              errorstatus={((validatePanCard(rePanCard) !== undefined && notEqualsZero(rePanCard)) || matchPanCard(panCard, rePanCard)) ? 'true' : 'false'}
                              errormessage={validatePanCard(rePanCard) || matchPanCard(panCard, rePanCard)}
                              onChange={(event) => setRePanCard(event.target.value.toUpperCase())} />
                          </div>
                        </div>
                        <div className="col-md-12 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Upload Item File
                              <span style={{ color: "red" }}>*</span>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Only JPEG, PNG & JPG formats are supported)
                              </div>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Max image size is 5MB)
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" id="file" type="file" required className="inputfile" data-multiple-caption="{count} files selected" multiple onChange={handleChangePanCard} />
                            <label htmlFor="file" title="No File Choosen">
                              <img alt="" src={pancardImage ? pancardImage : '/images/kyc/pan_card.png'} className="img-fluid" width="300" height="300" />
                            </label>

                          </div>
                        </div>
                      </div>
                    </div> : <div className="form-field-wrapper ">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Other Document Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="panCard" value={panCard} onChange={(event) => setPanCard(event.target.value.toUpperCase())} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="field-box">
                            <label htmlFor="name" className="form-label">
                              Confirm Other Document Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <DefaultInput type="text" required name="rePanCard" value={rePanCard} onChange={(event) => setRePanCard(event.target.value.toUpperCase())}
                              errormessage={matchDocument(panCard, rePanCard)} errorstatus={(matchDocument(panCard, rePanCard)) ? 'true' : 'false'} />
                          </div>
                        </div>
                        <div className="col-md-12 upload-area">
                          <div className="upload-formate mb-3">
                            <h6 className="title mb-1">
                              Upload Item File
                              <span style={{ color: "red" }}>*</span>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Only JPEG, PNG & JPG formats are supported)
                              </div>
                              <div style={{ color: "red", fontSize: "small" }}>
                                (Max image size is 5MB)
                              </div>
                            </h6>
                          </div>
                          <div className="brows-file-wrapper">
                            <input name="file" id="file" type="file" required className="inputfile" data-multiple-caption="{count} files selected" multiple onChange={handleChangePanCard} />
                            <label htmlFor="file" title="No File Choosen">
                              <img alt="" src={pancardImage ? pancardImage : '/images/kyc/other_doc.png'} className="img-fluid" width="300" height="300" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>}

                    <div className="mb-4 pt-6">
                      <div className="title ">
                        Upload Selfie with ID
                        <span style={{ color: "red" }}>*</span>
                        <div style={{ color: "red", fontSize: "small" }}>
                          (Only JPEG, PNG & JPG formats are supported)
                        </div>
                        <div style={{ color: "red", fontSize: "small" }}>
                          (Max image size is 5MB)
                        </div>
                      </div>
                    </div>
                    <div className="form-field-wrapper">
                      <div className="row">
                        <div className="col-md-12 upload-area">
                          <div className="brows-file-wrapper">
                            <input name="file" type="file" required className="inputfile" onChange={handleChangeSelfie} />
                            <label htmlFor="file" title="No File Choosen">
                              <img alt="" src={selfieImage ? selfieImage : '/images/kyc/Selfie.png'} className="img-fluid" width="300" height="300" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className=" mt-7  btn w-100 btn-gradient btn-medium justify-content-center">
                      <span>Submit for verification</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      </section>
    </>
  );
};

export default KycPage;