import React, { useState, useEffect } from "react";
import LoaderHelper from "../../Utils/Loading/LoaderHelper";
import AuthService from "../../Api/Api_Services/AuthService";
import DataTableBase from "../../Utils/DataTable";
import { useParams } from "react-router-dom";
import Moment from "react-moment";

const CommitDetails = () => {
    const { projectId } = useParams()
    const [commitList, setCommitList] = useState([]);

    const getCommitList = async () => {
        LoaderHelper.loaderStatus(true);
        const result = await AuthService.commitHistory(projectId)
        if (result?.success) setCommitList(result?.data?.reverse()?.map((data, index) => ({ ...data, index: index + 1 })));
    };

    const fundsColumn = [
        { name: <>Serial No.</>, wrap: true, selector: (row) => row.index, },
        { name: <>Commit Date</>, wrap: true, selector: (row) => <Moment format="DD-MM-YY , hh:mm">{row?.commitDate}</Moment>, },
        { name: <>Project Name</>, wrap: true, selector: (row) => row?.projectName, },
        { name: <>Commited Currency</>, wrap: true, selector: (row) => row?.commitCurrency, },
        { name: <>Commited Quantity</>, wrap: true, selector: (row) => row?.committedQuantity, },
        { name: <>Estimated Rewards</>, wrap: true, selector: (row) => row?.estimatedRewards, },
        { name: <>Valid Quantity</>, wrap: true, selector: (row) => row?.validQuantity, },
    ];

    useEffect(() => {
        getCommitList();
    }, []);


    return (
        <>
            <div className="wallet_bal_sec" >
                <section  >
                    <div className="container">
                        <div className="tab-content custom-tab-content p-0 mt-3">
                            <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds">
                                <div className="table-responsive">
                                    <DataTableBase columns={fundsColumn} data={commitList} pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div>
        </>
    );
};

export default CommitDetails;
