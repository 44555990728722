import React from "react";

const ComingSoonPage = () => {

    return (
        <section className="soon_sec">
            <div className="container">
                <div className="soon_img" >
                    <img alt="" src="/images/error.svg" className="img-fluid" />
                </div>
            </div>
        </section>
    );

}

export default ComingSoonPage;