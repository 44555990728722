import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import 'swiper/css';
import "swiper/css/pagination";
import { ApiConfig } from "../../Api/Api_Config/ApiEndpoints";
import AuthService from "../../Api/Api_Services/AuthService";
import LoaderHelper from '../../Utils/Loading/LoaderHelper';
import { SocketContext } from "../../Utils/SocketContext";

const MarketPage = () => {
  const token = sessionStorage.getItem("CTEX_AUTH_TOKEN");
  const [AllData, setAllData] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const [categoryList, setcategoryList] = useState();
  const [activeTab, setActiveTab] = useState("ALL");
  const [activeCategory, setActiveCategory] = useState("All");
  const [search, setsearch] = useState('');
  const [favCoins, setfavCoins] = useState([]);
  const [FavCoinList, setFavCoinList] = useState([]);
  const [hotPairs, sethotPairs] = useState();
  const [newListedPair, setnewListedPair] = useState();
  const [sortOrder, setSortOrder] = useState(false);
  const [sortName, setsortName] = useState();

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    let interval;
    if (socket) {
      let payload = {
        'message': 'market',
      }
      socket.emit('message', payload);
      interval = setInterval(() => {
        let payload = {
          'message': 'market',
        }
        socket.emit('message', payload);
      }, 5000)

      socket.on('message', (data) => {
        setAllData(data?.pairs);
        sethotPairs(data?.hot?.slice(0, 3));
        setnewListedPair(data?.new_listed?.slice(0, 3));
      });
    }
    return (() => {
      clearInterval(interval)
    })
  }, [socket]);

  const CategoryList = async () => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.categoryList()
      if (result?.success) setcategoryList(result.data)
    } catch (error) { }
  };

  const HandleAddFav = async (pairId) => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.favoriteCoin(pairId)
      if (result?.success) FavoriteList()
    } catch (error) { }
  };

  const FavoriteList = async () => {
    try {
      const result = await AuthService.favoriteList()
      if (result?.success) {
        let filterdList = AllData?.filter((item) => {
          return result?.data?.pairs ? result?.data?.pairs?.includes(item?._id) : []
        })
        setfavCoins(result?.data?.pairs ? result?.data?.pairs : '');
        setFavCoinList(filterdList);
      }
    } catch (error) { }
  };

  // *******Ascending/Descending Sorting  ***********//
  const handleSort = (sortName) => {
    setsortName(sortName);
    let sortedArray = [...coinData];
    sortedArray.sort((a, b) => {
      if (sortOrder) {
        return a[sortName] - b[sortName];
      } else {
        return b[sortName] - a[sortName];
      }
    });
    setCoinData(sortedArray);
  };
  
  // ******* Search Filter ***********//
  useEffect(() => {
    let filteredData = AllData?.filter((item) => {
      return item?.base_currency?.toLowerCase()?.includes(search?.toLowerCase()) || item?.quote_currency?.toLowerCase()?.includes(search?.toLowerCase())
    })
    setCoinData(filteredData);
  }, [search]);

  // ******* Sorting Of Socket Data *********//
  useEffect(() => {
    if (AllData) {
      if (coinData?.length === 0) {
        setCoinData(AllData)
      }
      else if (!search) {
        let arrangedArray = [];
        const indexMap = new Map();
        for (let i = 0; i < coinData.length; i++) {
          const obj = coinData[i];
          indexMap.set(obj._id, i);
        }
        arrangedArray = AllData.sort((a, b) => indexMap.get(a._id) - indexMap.get(b._id));
        setCoinData(arrangedArray);
      }
    }
  }, [AllData]);

  useEffect(() => {
    CategoryList();
    token && FavoriteList()
  }, []);

  return (
    <>
      <section className="inner-page-banner pb-2">
        <div className="container">
          <div className="inner text-start p-0">
            <h1 className="title">Market </h1>
          </div>
        </div>
      </section>
      <section className=" live_prices mt-0 market_prices">
        <div className="container">
          <div className="row mb-4 gx-2" >
            <div className="col-lg-6" >
              <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                    <span className="rankItem_titleWrap__6tzjz"><span>Hot</span></span>
                  </div>
                </div>
                <div className="ant-card-body">
                  {hotPairs && hotPairs?.map((data) => {
                    return (
                      <a href={`/trade/${data?.base_currency}_${data?.quote_currency}`} className="ant-list row gx-0" key={data?._id} >
                        <div className=" col-4" >
                          <div className="spotName" >
                            <img alt="" src={ApiConfig?.appUrl + data?.icon_path} width="20" height="20" />
                            <div className="symblecurrency">{data?.base_currency}/{data?.quote_currency}</div>
                          </div>
                        </div>
                        <div className="col-5 text-end" ><span>{data?.buy_price?.toFixed(8)}</span></div>
                        <div className="col-3 text-end" >
                          <span className={data?.change >= 0 ? "text-success" : "text-danger"}>{data?.change?.toFixed(5)}</span>
                        </div>
                      </a>)
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-6" >
              <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                    <span className="rankItem_titleWrap__6tzjz"><span>Newest</span></span>
                  </div>
                </div>
                <div className="ant-card-body">
                  {newListedPair && newListedPair?.map((data) => {
                    return (
                      <a href={`/trade/${data?.base_currency}_${data?.quote_currency}`} className="ant-list row gx-0" key={data?._id} >
                        <div className=" col-4" >
                          <div className="spotName" >
                            <img alt="" src={ApiConfig?.appUrl + data?.icon_path} width="20" height="20" />
                            <div className="symblecurrency">
                              {data?.base_currency}/{data?.quote_currency}
                            </div>
                          </div>
                        </div>
                        <div className="col-5 text-end" >
                          <span>{data?.buy_price?.toFixed(8)}</span>
                        </div>
                        <div className="col-3 text-end" >
                          <span className={data?.change >= 0 ? "text-success" : "text-danger"}>{data?.change?.toFixed(5)}</span>
                        </div>
                      </a>)
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="d-flex-between mb-5 custom_dlflex">
            <ul className="nav custom-tabs">
              <li><a data-bs-toggle="tab" href="#tt_history" onClick={token && FavoriteList}>Favorite</a></li>
              <li><a className="active" data-bs-toggle="tab" href="#funds">Spot</a>
              </li>
            </ul>
            <div className="searchBar custom-tabs" ><i className="ri-search-2-line"></i>
              <input type="search" className="custom_search" placeholder="Search Crypto" value={search} onChange={(e) => { setsearch(e.target.value) }} />
            </div>
          </div>
          <div className="tab-content custom-tab-content p-0">
            <div className="tab-pane container active show fade" id="funds" >
              <div className="mrt_row" >
                <div className="btn-group btn_radio_group " role="group" aria-label="Basic radio toggle button group">
                  <button onClick={() => setActiveTab('ALL')} type="button" className={`btn  btn-outline-primary ${activeTab === 'ALL' && 'active'}`} htmlFor="ALL">ALL</button>
                  <button onClick={() => setActiveTab('CTEX')} type="button" className={`btn  btn-outline-primary ${activeTab === 'CTEX' && 'active'}`} htmlFor="CTEX">CTEX</button>
                  <button onClick={() => setActiveTab('USDT')} type="button" className={`btn  btn-outline-primary ${activeTab === 'USDT' && 'active'}`} htmlFor="USDT">USDT</button>
                </div>
                {/* <div className="scroll-subtabs" >
                  <a href="#/" className={`subtabs_link ${activeCategory === 'All' && 'active'}`} onClick={() => setActiveCategory('All')}>All</a>
                  {categoryList && categoryList?.map((data) => {
                    return (
                      <a key={data?._id} href="#/" className={`subtabs_link ${activeCategory === data?.category && 'active'}`}
                        onClick={() => setActiveCategory(data?.category)}>{data?.category}
                      </a>
                    )
                  })}
                </div> */}
              </div>
              <div className="table-responsive" >
                <table className="table " >
                  <thead>
                    <tr>
                      <th>Pair</th>

                      <th><div className="d-flex align-items-center"  >Price<i style={{ color: (sortName === 'buy_price' && sortOrder) ? 'green' : (sortName === 'buy_price' && !sortOrder) ? '#a40101' : '' }} onClick={() => { handleSort('buy_price'); setSortOrder(!sortOrder) }} className="ri-arrow-up-down-fill ms-1"></i></div></th>

                      <th><div className="d-flex align-items-center" >24H Point<i style={{ color: (sortName === 'change' && sortOrder) ? 'green' : (sortName === 'change' && !sortOrder) ? '#a40101' : '' }} onClick={() => { handleSort('change'); setSortOrder(!sortOrder) }} className="ri-arrow-up-down-fill ms-1"></i></div></th>

                      <th><div className="d-flex align-items-center" >24H High<i style={{ color: (sortName === 'high' && sortOrder) ? 'green' : (sortName === 'high' && !sortOrder) ? '#a40101' : '' }} onClick={() => { handleSort('high'); setSortOrder(!sortOrder) }} className="ri-arrow-up-down-fill ms-1"></i></div></th>

                      <th><div className="d-flex align-items-center" >24H Low<i style={{ color: (sortName === 'low' && sortOrder) ? 'green' : (sortName === 'low' && !sortOrder) ? '#a40101' : '' }} onClick={() => { handleSort('low'); setSortOrder(!sortOrder) }} className="ri-arrow-up-down-fill ms-1"></i></div></th>

                      <th><div className="d-flex align-items-center" >24H Vol<i style={{ color: (sortName === 'volume' && sortOrder) ? 'green' : (sortName === 'volume' && !sortOrder) ? '#a40101' : '' }} onClick={() => { handleSort('volume'); setSortOrder(!sortOrder) }} className="ri-arrow-up-down-fill ms-1"></i></div></th>
                      <th>Operation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coinData?.length > 0 ? coinData?.map((item) => (
                      ((activeTab === item?.quote_currency || activeTab === 'ALL') && (activeCategory === item?.category || activeCategory === 'All')) &&
                      <tr key={item?._id}>
                        <td>
                          <div className="td_div">
                            {token && <span className={"star_btn btn_icon active"}><i className={favCoins?.includes(item?._id) ? "ri ri-star-fill text-warning me-2 " : "ri ri-star-line me-2 "} onClick={() => { HandleAddFav(item?._id) }} > </i></span>}
                            <img alt=" "
                              src={ApiConfig.baseUrl + item?.icon_path}
                              className="img-fluid icon_img coinimg me-2 " />
                            {item?.base_currency}/{item?.quote_currency}
                          </div>
                        </td>
                        <td><b>{item?.buy_price?.toFixed(8)}</b></td>
                        <td className={item?.change >= 0 ? "color-green text-success" : "color-red text-danger"}><b>{item?.change?.toFixed(8)}</b>
                        </td>
                        <td><b>{item?.high?.toFixed(8)}</b></td>
                        <td><b>{item?.low?.toFixed(8)}</b></td>
                        <td><b>{(item?.volume?.toFixed(8))}</b></td>
                        <td><a href={`/trade/${item?.base_currency}_${item?.quote_currency}`} className="btn btn-gradient btn-sm"><span>Trade</span></a></td>
                      </tr>
                    )) : <tr rowSpan="5">
                      <td colSpan="12">
                        <p style={{ textAlign: "center" }}>
                          No Data Available
                        </p>
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane container fade" id="tt_history">
              <div>
                {token ?
                  <table className="table ">
                    <thead>
                      <tr>
                        <th> Pair</th>
                        <th> Price</th>
                        <th> Change</th>
                        <th> 24H High</th>
                        <th> 24H Low</th>
                        <th> 24H Vol</th>
                        <th> Operation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {FavCoinList?.length > 0 ? FavCoinList?.map((item) => (
                        <tr key={item?._id}>
                          <td>
                            <div className="td_div">
                              <span className={"star_btn btn_icon active"}>
                                <i className="ri-star-fill" onClick={() => { HandleAddFav(item?._id) }}></i></span>
                              <img alt="" src={ApiConfig?.baseUrl + item?.icon_path}
                                className="img-fluid icon_img coinimg me-2 " />
                              {item?.base_currency}/{item?.quote_currency}
                            </div>
                          </td>
                          <td><b>{item?.buy_price?.toFixed(8)}</b></td>
                          <td className={item?.change >= 0 ? "color-green text-success" : "color-red text-danger"}>
                            <b>{item?.change?.toFixed(8)}</b>
                          </td>
                          <td><b>{item?.high?.toFixed(8)}</b></td>
                          <td><b>{item?.low?.toFixed(8)}</b></td>
                          <td><b>{(item?.volume?.toFixed(8))}</b></td>
                          <td><a href={`/trade/${item?.base_currency}_${item?.quote_currency}`} className="btn btn-gradient btn-sm"><span>Trade</span></a></td>
                        </tr>
                      )) : <tr rowSpan="5">
                        <td colSpan="12">
                          <p style={{ textAlign: "center" }}>
                            No data Available
                          </p>
                        </td>
                      </tr>}
                    </tbody>
                  </table> : <div className="favouriteData">
                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                    <p className="mt-3 mb-4" > No results.... Go to <Link className="btn-link" to="#" >Sign in</Link> and add your favorite coins from Spot. </p>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarketPage;
