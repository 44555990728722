import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import './trade.css'
import moment from 'moment';
import { ApiConfig } from '../../Api/Api_Config/ApiEndpoints';
import LoaderHelper from '../../Utils/Loading/LoaderHelper';
import AuthService from '../../Api/Api_Services/AuthService';
import { alertSuccessMessage } from '../../Utils/CustomAlertMessage';
import TVChartContainer from "../../Utils/TVChartContainer";
import { ProfileContext } from '../../Context';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { SocketContext } from '../../Utils/SocketContext';

const Trade = () => {
    let params = useParams()
    let URL = params?.pairs?.split('_');
    const { buySellSkip, handleUserDetials, kycStatus } = useContext(ProfileContext);
    const token = sessionStorage.getItem('CTEX_AUTH_TOKEN');
    const userId = sessionStorage.getItem('userId');
    const [urlPath, setUrlPath] = useState(URL ? URL : []);
    const [search, setsearch] = useState('');
    const [AllData, setAllData] = useState([]);
    const [BuyOrders, setBuyOrders] = useState([]);
    const [CoinPairDetails, setCoinPairDetails] = useState();
    const [RecentTrade, setRecentTrade] = useState([]);
    const [SellOrders, setSellOrders] = useState([]);
    const [SelectedCoin, setSelectedCoin] = useState();
    const [baseCurId, setbaseCurId] = useState();
    const [quoteCurId, setquoteCurId] = useState();
    const [buyprice, setbuyprice] = useState(0);
    const [buyamount, setbuyamount] = useState(1);
    const [sellPrice, setsellPrice] = useState(0);
    const [sellAmount, setsellAmount] = useState(1);
    const [infoPlaceOrder, setinfoPlaceOrder] = useState('LIMIT');
    const [coinFilter, setcoinFilter] = useState('ALL');
    const [BuyCoinBal, setBuyCoinBal] = useState();
    const [SellCoinBal, setSellCoinBal] = useState();
    const [orderType, setorderType] = useState('All');
    const [buyTriggerPrice, setbuyTriggerPrice] = useState();
    const [sellTrigegrPrice, setsellTrigegrPrice] = useState();
    const [priceDecimal, setpriceDecimal] = useState(8);
    const [pastOrders, setpastOrders] = useState([]);
    const [favCoins, setfavCoins] = useState([]);
    const [sellOrderPrice, setsellOrderPrice] = useState(undefined);
    const [buyOrderPrice, setbuyOrderPrice] = useState(undefined);
    const [tradeHistory, setTradeHistory] = useState([]);
    const [openOrders, setopenOrders] = useState([]);
    const [categoryList, setcategoryList] = useState([]);
    const [activeCategory, setActiveCategory] = useState("All");
    const [toggleChart, setToggleChart] = useState('chart');
    const [CoinDetails, setCoinDetails] = useState([]);
    const [tokenInfoLinks, settokenInfoLinks] = useState([]);
    const [loader, setloader] = useState(true);
    const [showCoinList, setShowCoinList] = useState(false);
    const [pairDetails, setPairDetails] = useState({ priceChange: 0, priceHigh: 0, priceLow: 0, volume: 0, });
    const [checkVisibleTable, setCheckVisibleTable] = useState({ OrderHistory: false, TrdeHistory: false });


    // ********* Socket Connection and Data fetch ********** //
    const { socket } = useContext(SocketContext);

    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'exchange',
            }
            socket.emit('message', payload);
            socket.on('message', (data) => {
                setAllData(data);
                setBuyOrders(data?.buy_order);
                setRecentTrade(data?.recent_trades);
                setSellOrders(data?.sell_order?.reverse());
                setBuyCoinBal(data?.balance?.quote_currency_balance);
                setSellCoinBal(data?.balance?.base_currency_balance);
                setopenOrders(data?.open_orders?.reverse());
                setloader(false);
            });
        }
    }, [socket]);

    // ********* Auto Select Coin Pair after Socket Connection ********** //
    useEffect(() => {
        if (!SelectedCoin && CoinPairDetails) {
            var Pair;
            var filteredData;
            if (urlPath?.length > 0) {
                filteredData = CoinPairDetails?.filter?.((item) => {
                    return urlPath[0]?.includes(item?.base_currency) && urlPath[1]?.includes(item?.quote_currency)
                })
            }
            if (filteredData?.length > 0) {
                Pair = filteredData[0]
            }
            else {
                Pair = CoinPairDetails[0]
            }
            navigate(`/trade/${Pair?.base_currency}_${Pair?.quote_currency}`);
            setloader(true);
            setsellOrderPrice(undefined);
            setbuyOrderPrice(undefined);
            setSelectedCoin(Pair);
            setbaseCurId(Pair?.base_currency_id);
            setquoteCurId(Pair?.quote_currency_id);
            setbuyprice(Pair?.buy_price);
            setsellPrice(Pair?.sell_price);
            coinDetails(Pair?.base_currency_id);
            let payload = {
                'message': 'exchange',
                'userId': userId,
                'base_currency_id': Pair?.base_currency_id,
                'quote_currency_id': Pair?.quote_currency_id,
            }
            socket.emit('message', payload);
        }
    }, [CoinPairDetails, infoPlaceOrder]);

    useEffect(() => {
        let interval;
        if (baseCurId && quoteCurId) {
            interval = setInterval(() => {
                let payload = {
                    'message': 'exchange',
                    'userId': userId,
                    'base_currency_id': baseCurId,
                    'quote_currency_id': quoteCurId,
                }
                socket.emit('message', payload);
            }, 5000)
        }
        return (() => {
            clearInterval(interval)
        })
    }, [baseCurId]);



    // ******** Check Decimals Of Buy Price ************//
    // function countDecimalPlaces(number) {
    //     const decimalString = number?.toString();
    //     const decimalIndex = decimalString?.indexOf('.');
    //     if (decimalIndex !== -1) {
    //         let length = decimalString?.length - decimalIndex - 1;
    //         if (length > 5) {
    //             setdecimals(length);
    //         } else {
    //             setdecimals(5)
    //         }
    //     } else {
    //         setdecimals(5)
    //     }
    // };


    // ********* Generate Dynamic Options for Price Decimals In order book ******** // 
    // const generateOptions = () => {
    //     const options = [];
    //     for (let i = decimals; i >= 1; i--) {
    //         const value = Math.pow(10, -i);
    //         options.push(
    //             <option selected={i === decimals} key={i} value={i}>
    //                 {value?.toFixed(i)}
    //             </option>
    //         );
    //     }
    //     return options;
    // };


    // ********* Filter Decimals By Sell price ********** //
    // useEffect(() => {
    //     countDecimalPlaces(sellPrice);
    // }, [sellPrice]);


    // ********* Update Buy Sell 24HChange High Low Volume Price********** //
    useEffect(() => {
        if (AllData && SelectedCoin) {
            let filteredData = AllData?.pairs?.filter((item) => {
                return item?.base_currency_id === SelectedCoin?.base_currency_id && item?.quote_currency_id === SelectedCoin?.quote_currency_id
            })
            if (filteredData) {
                setbuyprice(filteredData[0]?.buy_price);
                setsellPrice(filteredData[0]?.sell_price);
                setPairDetails({
                    priceChange: filteredData[0]?.change,
                    priceHigh: filteredData[0]?.high,
                    priceLow: filteredData[0]?.low,
                    volume: filteredData[0]?.volume,
                })
            }
        }
    }, [AllData]);


    // ********* Search Coins ********** //
    useEffect(() => {
        let filteredData = AllData?.pairs?.filter((item) => {
            return item?.base_currency?.toLowerCase()?.includes(search.toLowerCase()) || item?.quote_currency?.toLowerCase()?.includes(search?.toLowerCase())
        })
        setCoinPairDetails(filteredData)

    }, [search, AllData]);


    const functCheckRef = useRef(true);
    useEffect(() => {
        if (functCheckRef.current) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            CategoryList();
            NotificationList();
            coinDetails(SelectedCoin?.base_currency_id)
            token && favoriteList();
            functCheckRef.current = false;
        }
    }, []);


    const handleOrderPlace = async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
        if (!buyprice || +buyprice === 0 || !buyamount || +buyamount === 0) {
            return
        }
        LoaderHelper.loaderStatus(true);
        await AuthService.placeOrder(infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side).then((result) => {
            if (result?.success) {
                alertSuccessMessage('Order Placed Successfully!!')
                setbuyOrderPrice(undefined);
                setsellOrderPrice(undefined);
                setbuyamount(1);
                setsellAmount(1);
            }
        })
    };


    const handleShowCoinList = () => {
        if (showCoinList) setShowCoinList(false);
        else setShowCoinList(true);
    }

    const skipModel = async (check) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.skipModel(check).then((result) => {
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserDetials();
            }
        })
    };

    const handlePastOrder = async (base_currency_id, quote_currency_id) => {
        setCheckVisibleTable({
            OrderHistory: true,
            TrdeHistory: false,
        });
        LoaderHelper.loaderStatus(true);
        await AuthService.pastOrder(base_currency_id, quote_currency_id).then((result) => {
            if (result?.success) {
                setpastOrders(result?.data)
            }
        })
    };

    const handleTradeHistory = async (base_currency_id, quote_currency_id) => {
        setCheckVisibleTable({
            OrderHistory: false,
            TrdeHistory: true,
        });
        LoaderHelper.loaderStatus(true);
        await AuthService.userTradeHistory(base_currency_id, quote_currency_id).then((result) => {
            if (result?.success) {
                setTradeHistory(result?.data);
            }
        })
    };

    const cancelOrder = async (orderId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.cancelOrder(orderId).then((result) => {
            if (result?.success) {
                alertSuccessMessage('Order Cancelled Successfully');
            }
        })
    };

    const coinDetails = async (currId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.coinDetails(currId).then((result) => {
            if (result?.success) {
                setCoinDetails(result?.data);
                settokenInfoLinks(result?.data?.links?.length > 0 ? JSON.parse(result?.data?.links) : [])
            }
        })
    };

    const handleAddFav = async (pairId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.favoriteCoin(pairId).then((result) => {
            if (result?.success) {
                favoriteList()
            }
        })
    };

    const favoriteList = async () => {
        await AuthService.favoriteList().then((result) => {
            if (result?.success) {
                setfavCoins(result?.data?.pairs ? result?.data?.pairs : ['']);
            }
        });
    };

    const CategoryList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.categoryList().then(async result => {
            if (result?.success) {
                setcategoryList(result?.data);
            }
        });
    };

    const navigate = useNavigate()
    const handleSelectCoin = (data) => {
        navigate(`/trade/${data?.base_currency}_${data?.quote_currency}`);
        setloader(true);
        setsellOrderPrice(undefined);
        setbuyOrderPrice(undefined);
        setSelectedCoin(data);
        setbaseCurId(data?.base_currency_id);
        setquoteCurId(data?.quote_currency_id);
        setbuyprice(data?.buy_price);
        setsellPrice(data?.sell_price);
        coinDetails(data?.base_currency_id);

        if (checkVisibleTable?.OrderHistory && token) {
            handlePastOrder(data?.base_currency_id, data?.quote_currency_id);
        } else if (checkVisibleTable?.TrdeHistory && token) {
            handleTradeHistory(data?.base_currency_id, data?.quote_currency_id);
        }

        let payload = {
            'message': 'exchange',
            'userId': userId,
            'base_currency_id': data?.base_currency_id,
            'quote_currency_id': data?.quote_currency_id,
        }
        socket.emit('message', payload);


    };

    const handleOrderType = (e) => {
        setinfoPlaceOrder(e.target.value);
        if (e.target.value === 'MARKET') {
            setsellOrderPrice(undefined);
            setbuyOrderPrice(undefined);
            setbuyprice(SelectedCoin?.buy_price)
            setsellPrice(SelectedCoin?.sell_price)
        };
    };

    // ********* Filter Decimals By Sell price ********** //
    // useEffect(() => {
    //     countDecimalPlaces(sellPrice);
    // }, [sellPrice]);


    // ******** Check Decimals Of Buy Price ************//
    // function countDecimalPlaces(number) {
    //     let zeroes = countContinuousZerosAfterDecimal(number);
    //     setdecimals(zeroes)
    // };
    // function countContinuousZerosAfterDecimal(number) {
    //     const numStr = number.toString();

    //     let zeroCount = 0;
    //     let counting = false;

    //     for (let i = 0; i < numStr.length; i++) {
    //         const char = numStr[i];
    //         if (char === '.') {
    //             counting = true;
    //         } else if (counting && char === '0') {
    //             zeroCount++;
    //         } else if (counting && char !== '0') {
    //             break;
    //         }
    //     }
    //     console.log(zeroCount, 'zeroCount');
    //     if(zeroCount > 2){
    //         return 5;
    //     }else{
    //         return zeroCount + 4;
    //     }

    // }

    const HandleBuySellInput = (e) => {
        let decimals = 5;
        let regex = BUY_PRICE_REGEX();
        let regex2 = USER_PRICE_REGEX(decimals);
        let inputValue = e.target.value;
        switch (e.target.name) {
            case 'buyPricess':
                if (regex.test(inputValue) || inputValue === '') {
                    setbuyOrderPrice(inputValue);
                }
                break;
            case 'sellPricess':
                if (regex.test(inputValue) || inputValue === '') {
                    setsellOrderPrice(inputValue)
                }
                break;
            case 'buyAmount':
                if (regex2.test(inputValue) || inputValue === '') {
                    setbuyamount(e.target.value)
                }
                break;
            case 'sellAmount':
                if (regex2.test(inputValue) || inputValue === '') {
                    setsellAmount(e.target.value)
                }
                break;
            default:
                break;
        }
    };

    const BUY_PRICE_REGEX = () => {
        return new RegExp(/^(?!.*[-+*/])\d*(?:\.\d*)?$/);
    };

    const USER_PRICE_REGEX = (decimals) => {
        return new RegExp(`^(?!.*[-+*/])\\d*(?:\\.\\d{1,${decimals}})?$`);
    };

    const handleKeyPress = (e) => {
        if (e.key === '-') {
            e.preventDefault();
        }
    };
    const [announcementBar, setannouncementBar] = useState(false);
    const [notificationList, setnotificationList] = useState([]);



    const NotificationList = async () => {
        await AuthService.notificationList().then(async result => {
            if (result?.success) {
                if (result?.data?.length > 0) {
                    setannouncementBar(true)
                    setnotificationList(result?.data);
                }
                else {
                    setannouncementBar(false)
                }

            }
        });
    };


    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
        const doc = new jsPDF('landscape', 'mm', 'a4');
        doc.text('Trading Report', 10, 10);
        const tableHeaders = ['Serial No.', 'Date/Time', 'Trading Currency', 'Side', 'Order Type', 'Price', 'Executed', 'Total', 'Fee'];
        const tableData = tradeHistory.map((trade, index) => [index + 1, moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"), `${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`, trade?.side, trade?.order_type, trade?.price, trade?.quantity, trade?.price * trade?.quantity, trade?.fee]);

        const fixedColumnWidths = [25, 30, 30, 20, 35, 30, 30, 30, 30];
        const columnStyles = {};
        tableHeaders.forEach((_, index) => {
            columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
        });

        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            theme: 'grid',
            columnStyles: columnStyles,
        });

        doc.save('TradeHistory.pdf');
    };

    return (
        <>
            {announcementBar &&
                <div className={`announce_bar ${(announcementBar) ? " " : 'd-none'}`} >
                    <div className="container-fluid" >
                        <div className="d-flex align-items-center justify-content-between" >
                            <i className="ri-megaphone-line me-2"></i>
                            <div className="mb-0 p_data" >
                                <Swiper
                                    loop={true}

                                    autoplay={{
                                        delay: 3500,
                                        pauseOnMouseEnter: true
                                    }}
                                    direction="vertical"
                                    modules={[Autoplay, Pagination, Navigation]}>
                                    {notificationList?.map((item) => {
                                        return (
                                            <SwiperSlide key={item?._id} >
                                                <div className="slidess" >
                                                    <div className="newsTicker_swipeItem__7WaHs" >
                                                        <span className="newsTicker_swipeText__w0lw1"> {item?.title} </span>
                                                        {item?.link && <a href='/Profile/Notifications' className="btn-link text-underline cursor-pointer">View More&gt;&gt;</a>}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                            <button className="close_btn " onClick={() => { setannouncementBar(false) }} >
                                <i className="ri-close-line"></i>
                            </button>
                        </div>
                    </div>
                </div>
            }
            <div className="trade-wrapper spot login_bg pb-3">
                <div className="container-fluid ">

                    <div className="row g-2" >





                        <div className="col-12 col-lg-12 col-xl-7  col-xxl-7 " >
                            <div className="trade_card p-2  overflow_card mb-1" >

                                {/* coinlist start here */}
                                <div className={`trade_card trade_card_spot   coinlist spotList ${showCoinList && ' active'} `} >
                                    <div className="spot-list-search">
                                        <div className="ivu-input" >
                                            <i className="ri-search-2-line"></i>
                                            <input autoComplete="off" spellCheck="false" type="search" placeholder="Search" className=""
                                                onChange={(e) => { setsearch(e.target.value) }} value={search} />
                                        </div>
                                    </div>

                                    <div className=" trade_tabs buy_sell_cards   ">
                                        <ul className="nav custom-tabs nav_order scroll_x">
                                            {token && <li className="favt-tab"><a data-bs-toggle="tab" href="#tab_fav" onClick={() => { setcoinFilter('FAV') }}>Favorites </a></li>}
                                            <li className="all-tab"><a className="active" data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('ALL') }}> All </a></li>
                                            <li className="usdt-tab"><a data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('USDT') }}>USDT </a></li>
                                            <li className="btc-tab"><a data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('CTEX') }} className="">CTEX</a></li>
                                        </ul>
                                    </div>

                                    <div className=" trade_tabs buy_sell_cards   ">
                                        <ul className="nav custom-tabs nav_order scroll_x">
                                            <li className="all-tab" onClick={() => setActiveCategory('All')}><a className={activeCategory === 'All' ? 'active' : ''} data-bs-toggle="tab" href="#All" >All </a></li>
                                            {categoryList && categoryList?.map((data, index) => {
                                                return (
                                                    <li key={index} className="all-tab" onClick={() => setActiveCategory(data?.category)}><a className={activeCategory === data?.category ? 'active' : ''} data-bs-toggle="tab" href="#All" >{data?.category} </a></li>
                                                )
                                            })}
                                        </ul>
                                    </div>

                                    <div className="price_card ">
                                        <div className="price_card_head markets_row__xELC__head ">
                                            <div>Pair</div>
                                            <div>Price</div>
                                            <div>Change</div>
                                        </div>
                                        <div className="price_card_body tab-content scroll_y scroll_y_coins " >
                                            <div className="tab-pane px-0" id="tab_fav" >
                                                {CoinPairDetails ? CoinPairDetails?.map((data, index) => {
                                                    return (
                                                        ((activeCategory === data?.category || activeCategory === 'All') &&
                                                            favCoins.includes(data?._id)) &&
                                                        <div key={index} className={`${SelectedCoin?._id === data?._id ? "active_pair" : ''} markets_row__xELC_ markets_rowItem__RPIFi markets_rowActive__NlFL8`}>
                                                            <div className="markets_col1__kEdFH" onClick={() => { handleSelectCoin(data); handleShowCoinList() }}>
                                                                <div className="markets_symbol__gj7q4">
                                                                    <div><span className="markets_strong__s_Hxk"></span><span>{`${data?.base_currency}/${data?.quote_currency}`}</span></div>
                                                                </div>
                                                                <div className="markets_secondLine__vuaeq"><span>{data?.name}</span></div>
                                                            </div>
                                                            <div className="markets_col2__RMpcV" onClick={() => handleSelectCoin(data)}>
                                                                <div className="markets_strong__s_Hxk">{data?.buy_price < 1 ? data?.buy_price : data?.buy_price?.toLocaleString()}</div>
                                                                <div className="markets_secondLine__vuaeq"><span className=""><span dir="ltr"><span  >$</span><span dir="ltr">{data?.buy_price < 1 ? data?.buy_price : data?.buy_price?.toLocaleString()}</span></span>
                                                                </span>
                                                                </div>
                                                            </div>
                                                            <div className="markets_col3__LCAVs" onClick={() => handleSelectCoin(data)}>
                                                                <div className={data?.change_percentage >= 0 ? "text-success markets_change__K2rx5" : "text-danger markets_change__K2rx5"}>{data?.change_percentage || data?.change_percentage === 0 ? `${parseFloat(data?.change_percentage?.toFixed(8))}%` : '---'}</div>
                                                                <div className="markets_secondLine__vuaeq">{data?.volume?.toLocaleString()}</div>
                                                            </div>
                                                            <div className="markets_favIcon__w4ieP">
                                                                {token && <i className={favCoins.includes(data?._id) ? "ri ri-star-fill text-warning" : "ri ri-star-fill"} onClick={() => { handleAddFav(data?._id) }} >
                                                                </i>}
                                                            </div>
                                                        </div>

                                                    )
                                                }) : null}
                                            </div>
                                            <div className="tab-pane px-0 active" id="tab_all" >
                                                {CoinPairDetails ? CoinPairDetails?.map((data, index) => {
                                                    return (
                                                        ((data?.quote_currency === coinFilter || coinFilter === 'ALL') && (activeCategory === data?.category || activeCategory === 'All')) &&
                                                        <div key={index} className={`${SelectedCoin?._id === data?._id ? "active_pair" : ''} markets_row__xELC_ markets_rowItem__RPIFi markets_rowActive__NlFL8`}>
                                                            <div className="markets_col1__kEdFH" onClick={() => { handleSelectCoin(data); handleShowCoinList() }}>
                                                                <div className="markets_symbol__gj7q4">
                                                                    <div><span className="markets_strong__s_Hxk"></span><span>{`${data?.base_currency}/${data?.quote_currency}`}</span></div>
                                                                </div>
                                                                <div className="markets_secondLine__vuaeq"><span>{data?.name}</span></div>
                                                            </div>
                                                            <div className="markets_col2__RMpcV" onClick={() => handleSelectCoin(data)}>
                                                                <div className="markets_strong__s_Hxk">{data?.buy_price < 1 ? data?.buy_price : data?.buy_price?.toLocaleString()}</div>
                                                                {/* <div className="markets_secondLine__vuaeq"><span className=""><span dir="ltr"><span  >$</span><span dir="ltr">{data?.buy_price < 1 ? data?.buy_price : data?.buy_price?.toLocaleString()}</span></span>
                                                                </span>
                                                                </div> */}
                                                            </div>
                                                            <div className="markets_col3__LCAVs" onClick={() => handleSelectCoin(data)}>
                                                                <div className={data?.change_percentage >= 0 ? "text-success markets_change__K2rx5" : "text-danger markets_change__K2rx5"}>{data?.change_percentage || data?.change_percentage === 0 ? `${parseFloat(data?.change_percentage?.toFixed(8))}%` : '---'}</div>
                                                                <div className="markets_secondLine__vuaeq">{data?.volume?.toLocaleString()}</div>
                                                            </div>
                                                            <div className="markets_favIcon__w4ieP">
                                                                {token && <i className={favCoins.includes(data?._id) ? "ri ri-star-fill text-warning" : "ri ri-star-line"} onClick={() => { handleAddFav(data?._id) }} >
                                                                </i>}
                                                            </div>
                                                        </div>
                                                    )
                                                }) : loader ? <div className="text-center no-data h-100 mb-0" >
                                                    <div className="loading-wave">
                                                        <div className="loading-bar"></div>
                                                        <div className="loading-bar"></div>
                                                        <div className="loading-bar"></div>
                                                        <div className="loading-bar"></div>
                                                    </div></div> : <p className="text-center no-data h-100 mb-0" >
                                                    <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                    No data Available
                                                </p>}
                                            </div>

                                        </div>


                                    </div>

                                </div>

                                {/* coinlist end here */}


                                <div className="headline_left__lBBPY">

                                    <div className="headline_left__lBBPY_leftmain d-flex align-items-center">

                                        <div className="headline_symbolName__KfmIZ mt_tr_pr">
                                            <div className="headline_bigName__dspVW " onClick={handleShowCoinList} >
                                                {showCoinList ? <i class="faaa ri-close-large-line" ></i> : <i class="faaa  ri-menu-add-line"></i>}


                                            </div>
                                            <div>
                                                <div className="headline_bigName__dspVW ">
                                                    <h1>{SelectedCoin?.name}</h1>
                                                    {token && <i className={favCoins.includes(SelectedCoin?._id) ? "ri ri-star-fill text-warning ms-1 cursor-pointer " : "ri ri-star-line ms-1 cursor-pointer  "} onClick={() => { handleAddFav(SelectedCoin?._id) }} ></i>}
                                                </div>
                                                <div className="headline_etfDisplay__P4Hdv"><span>{`${SelectedCoin?.base_currency || "--"}/${SelectedCoin?.quote_currency || "--"}`}</span></div>

                                            </div>
                                        </div>

                                        <div className="headline_leftItem__7BFYq headline_latestPrice__AYXu0">
                                            <div><span className="headline_title__x1csO">{buyprice}</span>
                                                <span className={`headline_change__tGyxo ${pairDetails?.priceChange >= 0 ? "text-success" : "text-danger"} ms-2`}>{(parseFloat(pairDetails?.priceChange?.toFixed(8)))}</span></div>
                                            <div className="headline_etfDisplay__P4Hdv"><span>$ {buyprice}</span></div>
                                        </div>
                                    </div>




                                    <div className="scroll-subtabs_scrollSubInfo__T5nZF headline_left__lBBPY_rightmain">
                                        <div className="scroll-subtabs_tabs__Prom8">
                                            <div className="scroll-subtabs_subMarketWrap__XVmHp">
                                                <div className="headline_extendInfoWrapper__dooIS">
                                                    <div className="headline_leftItem__7BFYq">
                                                        <div className="headline_withBorder__a6ZD2 ">24h High</div>
                                                        <div className="headline_title__x1csO text-success">
                                                            {pairDetails?.priceHigh?.toFixed(8) < 1 ? parseFloat(pairDetails?.priceHigh?.toFixed(8)) : (pairDetails?.priceHigh)?.toLocaleString()}
                                                        </div>
                                                    </div>
                                                    <div className="headline_leftItem__7BFYq">
                                                        <div className="headline_withBorder__a6ZD2 ">24h Low</div>
                                                        <div className="headline_title__x1csO text-danger">{pairDetails?.priceLow?.toFixed(8) < 1 ? parseFloat(pairDetails?.priceLow?.toFixed(8)) : (pairDetails?.priceLow)?.toLocaleString()}</div>
                                                    </div>
                                                    <div className="headline_leftItem__7BFYq">
                                                        <div className="headline_withBorder__a6ZD2">24h Volume ({SelectedCoin?.base_currency})</div>
                                                        <div className="headline_title__x1csO">{parseFloat(pairDetails?.volume?.toFixed(8))?.toLocaleString()}</div>
                                                    </div>
                                                    <div className="headline_leftItem__7BFYq">
                                                        <div className="headline_withBorder__a6ZD2">24h Amount ({SelectedCoin?.quote_currency})</div>
                                                        <div className="headline_title__x1csO">{parseFloat((pairDetails?.volume * buyprice)?.toFixed(8))?.toLocaleString()}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {/* Trade view */}
                            <div className="trade_card trade_chart   p-0"  >
                                <div className="treade_card_header" >
                                    <div className={`card_header_title ${toggleChart === 'chart' ? 'active' : ''}`} onClick={() => { setToggleChart('chart') }} >
                                        K-Line
                                    </div>
                                    <div className={`card_header_title ${toggleChart === 'tokenInfo' ? 'active' : ''}`} onClick={() => { setToggleChart('tokenInfo') }} >
                                        Token Info
                                    </div>
                                </div>
                                <div className="cstm_tabs" >
                                    <div className={`sctm_row ${toggleChart === 'chart' ? '' : 'd-none'} `}>
                                        {SelectedCoin?.base_currency === undefined ? <div style={{ width: '100%', height: '495px' }}>
                                            <div className="loading-wave " style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                        </div> :
                                            <TVChartContainer symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} />
                                        }
                                    </div>

                                    <div className={`sctm_row ${toggleChart === 'tokenInfo' ? '' : 'd-none'} `}>
                                        <div className="exchange_chartContent__Ijukg " >
                                            <div className="information_visible__9A4Mp information_wrapper__AR6LL">
                                                <div className="information_title__TYI4l">
                                                    <div className="information_icon__Lztnq">
                                                        <img src={CoinDetails?.icon_path ? ApiConfig?.baseUrl + CoinDetails?.icon_path : ''} alt="" className="img-fluid" height="22" width="22" />
                                                    </div>
                                                    <div className="information_name__ygI29 information_bright__F_RTd">{CoinDetails?.name}</div>
                                                </div>
                                                <div className="information_intro__ffLAj scroll-container scroll_y">
                                                    <div className="information_bright__F_RTd">{CoinDetails?.description}</div>
                                                </div>
                                                <div className="information_title__TYI4l"><span className="information_bright__F_RTd information_name__ygI29">Overview</span></div>
                                                <div className="information_other__OQlyX">
                                                    <div className="information_left__vgFSY">
                                                        <div className="information_infoItem__3_gv6">
                                                            <div>Name</div>
                                                            <div className="information_bright__F_RTd">{CoinDetails?.name}</div>
                                                        </div>
                                                        <div className="information_infoItem__3_gv6">
                                                            <div>Issue Date</div>
                                                            <div className="information_bright__F_RTd">{CoinDetails?.issue_date ? moment(CoinDetails?.issue_date).format("DD-MM-YYYY") : 'N/A'}</div>
                                                        </div>
                                                        {tokenInfoLinks?.length > 0 && <div className="information_infoItem__3_gv6">
                                                            <div>Related Links</div>
                                                            <div className="information_infoLink__shSep_row" >
                                                                {tokenInfoLinks?.map((item, index) => {
                                                                    return (
                                                                        <a key={index} className="information_infoLink__shSep" href={item?.description} rel="nofollow noopener noreferrer" target='_blank'>{item?.name}</a>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    <div className="information_right__kqyPI">
                                                        <div className="information_infoItem__3_gv6">
                                                            <div>Issue Price</div>
                                                            <div className="information_bright__F_RTd">{CoinDetails?.issue_price ? `${CoinDetails?.issue_price} ${SelectedCoin?.quote_currency}` : 'N/A'} </div>
                                                        </div>
                                                        <div className="information_infoItem__3_gv6">
                                                            <div>Max Supply</div>
                                                            <div className="information_bright__F_RTd">{CoinDetails?.total_supply?.toLocaleString()}</div>
                                                        </div>
                                                        <div className="information_infoItem__3_gv6">
                                                            <div>Circulating Supply</div>
                                                            <div className="information_bright__F_RTd">{CoinDetails?.circulating_supply?.toLocaleString()}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>


                        <div className="col-12 col-sm-6 col-lg-12 col-xl-5 col-xxl-5" >
                            <div className='row g-2' >
                                <div className='col-6' >
                                    <div className="trade_card  " >
                                        <div className="treade_card_header" >
                                            <div className="card_header_title active" >
                                                Order Book
                                            </div>
                                        </div>
                                        <div className=" trade_tabs buy_sell_cards  buy_sell_row d-flex-between   ">
                                            <ul className="nav custom-tabs nav_order">
                                                <li className="fav-tab"><a className="active" data-bs-toggle="tab" href="#all_orders"> <img alt='' src="/images/order_1.svg" width="22" height="11" />   </a></li>
                                                <li className="usdt-tab"><a data-bs-toggle="tab" href="#buy_orders"> <img alt='' src="/images/order_2.svg" width="22" height="11" />  </a></li>
                                                <li className="btc-tab"><a data-bs-toggle="tab" href="#sell_orders" className="me-0"> <img alt='' src="/images/order_3.svg" width="22" height="11" /> </a></li>
                                            </ul>
                                            <div className='num-div' >
                                                <select className="form-select num-select p-0 input-select" aria-label="Default select example" onClick={(e) => { setpriceDecimal(e.target.value) }} defaultValue='0.00000001'>
                                                    <option value={8}>
                                                        0.00000001
                                                    </option>
                                                    <option value={7}>
                                                        0.0000001
                                                    </option>
                                                    <option value={6}>
                                                        0.000001
                                                    </option>
                                                    <option value={5}>
                                                        0.00001
                                                    </option>
                                                    <option value={4}>
                                                        0.0001
                                                    </option>
                                                    <option value={3}>
                                                        0.001
                                                    </option>
                                                    <option value={2}>
                                                        0.01
                                                    </option>
                                                    <option value={1}>
                                                        0.1
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tab-content buy_sell_row_price" >
                                            <div className="tab-pane fade px-0  active show" id="all_orders">
                                                <div className="price_card">
                                                    <div className="price_card_head">
                                                        <div>Price({SelectedCoin?.quote_currency})</div>
                                                        <div>Quantity({SelectedCoin?.base_currency})</div>
                                                        <div>Total({SelectedCoin?.quote_currency})</div>
                                                    </div>
                                                    <div className="price_card_body scroll_y scroll_y_reverse scroll_y_hidden "   >
                                                        {(SellOrders?.length > 0 && !loader) ? SellOrders?.map((data, index) => {
                                                            return (
                                                                <div key={index} className="price_item_value cursor-pointer market_orders" onClick={() => { setbuyamount(parseFloat(data?.remaining?.toFixed(5))); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                                                                    <span className="d-flex align-items-center text-danger  "> {(data?.price)?.toFixed(priceDecimal)}</span>
                                                                    <span className=""> {(data?.remaining)?.toFixed(6)}</span>
                                                                    <span className="text-danger"> {(data?.price * data?.remaining)?.toFixed(6)}</span>
                                                                </div>
                                                            )
                                                        }) : loader ? <div className="text-center no-data h-100 mb-0" >
                                                            <div className="loading-wave">
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                            </div>
                                                        </div> : <p className="text-center no-data h-100 mb-0" >
                                                            <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                            No data Available
                                                        </p>

                                                        }
                                                    </div>
                                                    <div className="mrkt_trde_tab justify-content-center p-0" >
                                                    </div>
                                                    <div className="price_card_body scroll_y scroll_y_hidden"  >
                                                        {(BuyOrders?.length > 0 && !loader) ?
                                                            BuyOrders?.map((data, index) => {
                                                                return (
                                                                    <div key={index} className="price_item_value cursor-pointer market_orders" onClick={() => { setsellAmount(parseFloat(data?.remaining?.toFixed(5))); infoPlaceOrder !== 'MARKET' && setsellOrderPrice(data?.price) }}>
                                                                        <span className={"text-success d-flex lign-items-center"} >{(data?.price)?.toFixed(priceDecimal)}</span>
                                                                        <span className="">{(data?.remaining)?.toFixed(6)}</span>
                                                                        <span className="text-success">{(data?.price * data?.remaining)?.toFixed(6)}</span>
                                                                    </div>
                                                                )
                                                            }) : loader ? <div className="text-center no-data h-100 mb-0" >
                                                                <div className="loading-wave">
                                                                    <div className="loading-bar"></div>
                                                                    <div className="loading-bar"></div>
                                                                    <div className="loading-bar"></div>
                                                                    <div className="loading-bar"></div>
                                                                </div>
                                                            </div> : <p className="text-center no-data h-100 mb-0" >
                                                                <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                                No data Available
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade px-0 " id="buy_orders">
                                                <div className="price_card">
                                                    <div className="price_card_head">
                                                        <div>Price({SelectedCoin?.quote_currency})</div>
                                                        <div>Quantity({SelectedCoin?.base_currency})</div>
                                                        <div>Total({SelectedCoin?.quote_currency})</div>
                                                    </div>
                                                    <div className="price_card_body scroll_y scroll_y_full"  >
                                                        {(BuyOrders?.length > 0 && !loader) ?
                                                            BuyOrders?.map((data, index) => {
                                                                return (
                                                                    <div key={index} className="price_item_value cursor-pointer market_orders" onClick={() => { setsellAmount(parseFloat(data?.remaining?.toFixed(5))); infoPlaceOrder !== 'MARKET' && setsellOrderPrice(data?.price) }}>
                                                                        <span className={"text-success d-flex lign-items-center"} >{(data?.price)?.toFixed(priceDecimal)}</span>
                                                                        <span className="">{(data?.remaining)?.toFixed(6)}</span>
                                                                        <span className="text-success">{(data?.price * data?.remaining)?.toFixed(6)}</span>
                                                                    </div>
                                                                )
                                                            }) : loader ? <div className="text-center no-data h-100 mb-0" >
                                                                <div className="loading-wave">
                                                                    <div className="loading-bar"></div>
                                                                    <div className="loading-bar"></div>
                                                                    <div className="loading-bar"></div>
                                                                    <div className="loading-bar"></div>
                                                                </div>
                                                            </div> : <p className="text-center no-data h-100 mb-0" >
                                                                <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                                No data Available
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade px-0 " id="sell_orders">
                                                <div className="price_card">
                                                    <div className="price_card_head">
                                                        <div>Price({SelectedCoin?.quote_currency})</div>
                                                        <div>Quantity({SelectedCoin?.base_currency})</div>
                                                        <div>Total({SelectedCoin?.quote_currency})
                                                        </div>
                                                    </div>
                                                    <div className="price_card_body scroll_y scroll_y_reverse scroll_y_full"  >
                                                        {(SellOrders?.length > 0 && !loader) ? SellOrders?.map((data, index) => {
                                                            return (
                                                                <div key={index} className="price_item_value cursor-pointer market_orders" onClick={() => { setbuyamount(parseFloat(data?.remaining?.toFixed(5))); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                                                                    <span className="d-flex align-items-center text-danger "> {(data?.price)?.toFixed(priceDecimal)}</span>
                                                                    <span className=""> {(data?.remaining)?.toFixed(6)}</span>
                                                                    <span className="text-danger"> {(data?.price * data?.remaining)?.toFixed(6)}</span>
                                                                </div>
                                                            )
                                                        }) : loader ? <div className="text-center no-data h-100 mb-0" >
                                                            <div className="loading-wave">
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                            </div>
                                                        </div> : <p className="text-center no-data h-100 mb-0" >
                                                            <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                            No data Available
                                                        </p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6' >
                                    {/*  Buy Sell */}
                                    <div className="trade_card trade_chart  buysell_card ">
                                        <div className="treade_card_header buysell_heder " >

                                            <ul className="nav custom-tabs   ">
                                                <li className="buysell-tab buy-tab"><a className="active" data-bs-toggle="tab" href="#buytab" > Buy </a></li>
                                                <li className="  sell-tab"><a data-bs-toggle="tab" href="#selltab" > Sell </a></li>
                                            </ul>


                                        </div>
                                        <div className=" p-2 p-md-3" >
                                            <div className="col-md-12 mb-3" >
                                                <div className="spot_limit  " >
                                                    <select className="   input-select" name="infoPlaceOrder" onChange={handleOrderType} value={infoPlaceOrder}>
                                                        <option value="LIMIT">Limit</option>
                                                        <option value="MARKET">Market</option>
                                                        {/* <option value="STOPLIMIT">Stop Limit</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tab-content">

                                                <div className="tab-pane fade show active  px-0" id="buytab">
                                                    <form action="" className="buysellform data-buy">
                                                        <div className="actions_balance__kTHO0">

                                                            <span className="actions_primaryText__ufKT0"> Available Balance: </span>

                                                            <div>
                                                                <span>{BuyCoinBal ? parseFloat(BuyCoinBal)?.toFixed(6) : 0}</span> <span  className='text-muted' >{SelectedCoin?.quote_currency}</span>
                                                                <Link to="/FundPage" className="actions_deposit__Ydutk">
                                                                    <i className="ri-add-circle-fill"></i>
                                                                </Link>
                                                            </div>
                                                        </div>


                                                        {infoPlaceOrder === 'STOPLIMIT' &&
                                                            <div className="form-group" >
                                                                <div className="input-group mb-3">
                                                                    <input type="number" id="buyPrice" name="buyPrice" className="form-control" aria-label="Amount (to the nearest dollar)" placeholder='Trigger Price' value={buyTriggerPrice} onChange={(e) => { setbuyTriggerPrice(e.target.value) }} />
                                                                    <span className="input-group-text text-start"><small>Trigger Price - {SelectedCoin?.quote_currency}</small></span>
                                                                </div>
                                                            </div>}
                                                        <div className="form-group" >
                                                            <label>Price</label>
                                                            <div className="input-group mb-3">
                                                                <input type="number" id="buyPrice" name="buyPricess" className="form-control" aria-label="Amount (to the nearest dollar)" disabled={infoPlaceOrder === 'MARKET'} value={buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice} onChange={(e) => { HandleBuySellInput(e) }} onKeyDown={handleKeyPress} />
                                                                <span className="input-group-text text-start"><small>  {SelectedCoin?.quote_currency}</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group" >
                                                            <label>Amount</label>
                                                            <div className="input-group mb-3">
                                                                <input type="number" name="buyAmount" id="buyAmount" aria-invalid="true" className="form-control" aria-label="Amount (to the nearest dollar)" onChange={(e) => HandleBuySellInput(e)} value={buyamount} onKeyDown={handleKeyPress} />
                                                                <span className="input-group-text text-start"><small> {SelectedCoin?.base_currency}</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group" >
                                                            <div className="input-group mb-2">
                                                                <input type="number" id="buy_order_total" name="buy_order_total" className="form-control" aria-label="Amount (to the nearest dollar)" value={((buyOrderPrice !== undefined || buyOrderPrice) && buyamount) ? +buyOrderPrice * +buyamount : (buyprice && buyamount) ? +buyprice * +buyamount : 0} readOnly />
                                                                <span className="input-group-text text-start"><small>Total</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center" >
                                                            <div className="col-md-12" >
                                                                <div className="btn-group btn-group-mini  mb-3" role="group" aria-label="Basic radio toggle button group">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio125" autoComplete="off" />
                                                                    <label className="btn btn-outline-success" htmlFor="btnradio125" onClick={() => { setbuyamount(parseFloat((((BuyCoinBal / 100) * 25) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))?.toFixed(5))) }} >25%</label>
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" />
                                                                    <label className="btn btn-outline-success" htmlFor="btnradio250" onClick={() => { setbuyamount(parseFloat((((BuyCoinBal / 100) * 50) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))?.toFixed(5))) }}>50%</label>
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" />
                                                                    <label className="btn btn-outline-success" htmlFor="btnradio375" onClick={() => { setbuyamount(parseFloat((((BuyCoinBal / 100) * 75) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))?.toFixed(5))) }}>75%</label>
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3100" autoComplete="off" />
                                                                    <label className="btn btn-outline-success" htmlFor="btnradio3100" onClick={() => { setbuyamount(parseFloat((((BuyCoinBal / 100) * 100) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))?.toFixed(5))) }}>100%</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12" >
                                                                {token ?
                                                                    kycStatus != 2 ? <Link to='/KycPage' className="btn custom-btn btn-primary  w-100 my-1 my-md-0" >
                                                                        Verify KYC
                                                                    </Link> :
                                                                        (!buySellSkip ? <button type='button' className="btn custom-btn btn-success  w-100 my-1 my-md-0"
                                                                            data-bs-toggle="modal" data-bs-target="#BuyConfirm">
                                                                            Buy {SelectedCoin?.base_currency}
                                                                        </button> : <button type='button' className="btn custom-btn btn-success  w-100 my-1 my-md-0"
                                                                            onClick={() => handleOrderPlace(infoPlaceOrder, buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice, buyamount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'BUY')}>
                                                                            Buy {SelectedCoin?.base_currency}
                                                                        </button>) :
                                                                    <div>
                                                                        <Link to='/signup' className="btn custom-btn btn-primary  w-100 my-1 my-md-0" >
                                                                            Sign up
                                                                        </Link>
                                                                        <Link to='/login' className="btn custom-btn btn-muted  w-100 my-1 my-md-0" >
                                                                            Log In
                                                                        </Link>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="tab-pane    px-0" id="selltab">
                                                    <form action="" className="buysellform data-sell">
                                                        <div className="actions_balance__kTHO0">

                                                            <span className="actions_primaryText__ufKT0">Available Balance: </span>
                                                            {/* <span>{SellCoinBal ? parseFloat(SellCoinBal?.toFixed(6)) : 0}</span> <span>{SelectedCoin?.base_currency}</span> */}

                                                            <div>
                                                                <span>{SellCoinBal ? parseFloat(SellCoinBal?.toFixed(6)) : 0}</span> <span className='text-muted' >{SelectedCoin?.base_currency}</span>
                                                                <Link to="/FundPage" className="actions_deposit__Ydutk">
                                                                    <i className="ri-add-circle-fill"></i>
                                                                </Link>
                                                            </div>

                                                        </div>

                                                        {infoPlaceOrder === 'STOPLIMIT' &&

                                                            <div className="form-group" >
                                                                <div className="input-group mb-3">
                                                                    <input type="number" id="buyPrice" name="buyPrice" className="form-control" aria-label="Amount (to the nearest dollar)" placeholder='Trigger Price' value={sellTrigegrPrice} onChange={(e) => { setsellTrigegrPrice(e.target.value) }} />
                                                                    <span className="input-group-text text-start"><small>Trigger Price - {SelectedCoin?.quote_currency}</small></span>
                                                                </div>
                                                            </div>}

                                                        <div className="form-group" >
                                                            <label>Price</label>
                                                            <div className="input-group mb-3">
                                                                <input type="number" id="buyPrice" name="sellPricess" className="form-control" aria-label="Amount (to the nearest dollar)" value={sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : sellPrice} disabled={infoPlaceOrder === 'MARKET'} onChange={(e) => { HandleBuySellInput(e) }} onKeyDown={handleKeyPress} />
                                                                <span className="input-group-text text-start" ><small> {SelectedCoin?.quote_currency}</small></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group" >
                                                            <label>Amount</label>
                                                            <div className="input-group mb-3">
                                                                <input type="number" name="sellAmount" id="sellAmount" aria-invalid="true" className="form-control" aria-label="Amount (to the nearest dollar)" onChange={(e) => HandleBuySellInput(e)} value={sellAmount} onKeyDown={handleKeyPress} />
                                                                <span className="input-group-text text-start"><small>{SelectedCoin?.base_currency}</small></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group" >
                                                            <div className="input-group mb-2">
                                                                <input type="number" id="buy_order_total" name="buy_order_total" className="form-control" aria-label="Amount (to the nearest dollar)" onChange={() => { }} value={((sellOrderPrice !== undefined || sellOrderPrice) && sellAmount) ? +sellOrderPrice * +sellAmount : (sellPrice && sellAmount) ? +sellPrice * +sellAmount : 0} readOnly />
                                                                <span className="input-group-text text-start"><small>Total</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center" >
                                                            <div className="col-md-12" >
                                                                <div className="btn-group btn-group-mini  mb-3" role="group" aria-label="Basic radio toggle button group">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio15" autoComplete="off" />
                                                                    <label className="btn btn-outline-danger" htmlFor="btnradio15" onClick={() => { setsellAmount(parseFloat(((SellCoinBal / 100) * 25)?.toFixed(5))) }}>25%</label>
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio20" autoComplete="off" />
                                                                    <label className="btn btn-outline-danger" htmlFor="btnradio20" onClick={() => { setsellAmount(parseFloat(((SellCoinBal / 100) * 50)?.toFixed(5))) }}>50%</label>
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio35" autoComplete="off" />
                                                                    <label className="btn btn-outline-danger" htmlFor="btnradio35" onClick={() => { setsellAmount(parseFloat(((SellCoinBal / 100) * 75)?.toFixed(5))) }}>75%</label>
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio300" autoComplete="off" />
                                                                    <label className="btn btn-outline-danger" htmlFor="btnradio300" onClick={() => { setsellAmount(parseFloat(((SellCoinBal / 100) * 100)?.toFixed(5))) }}>100%</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12" >
                                                                {token ?
                                                                    kycStatus != 2 ? <Link to='/KycPage' className="btn custom-btn btn-primary  w-100 my-1 my-md-0" >
                                                                        Verify KYC
                                                                    </Link> : (!buySellSkip ?
                                                                        <button type='button' className="btn custom-btn btn-danger  w-100 my-1 my-md-0"
                                                                            data-bs-toggle="modal" data-bs-target="#SellConfirm">
                                                                            Sell {SelectedCoin?.base_currency}
                                                                        </button> : <button type='button' className="btn custom-btn btn-danger  w-100 my-1 my-md-0" onClick={() => handleOrderPlace(infoPlaceOrder, sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : sellPrice, sellAmount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'SELL')}>
                                                                            Sell {SelectedCoin?.base_currency}
                                                                        </button>) :
                                                                    <div>
                                                                        <Link to='/signup' className="btn custom-btn btn-primary  w-100 my-1 my-md-0" >
                                                                            Sign up
                                                                        </Link>
                                                                        <Link to='/login' className="btn custom-btn btn-muted  w-100 my-1 my-md-0" >
                                                                            Log In
                                                                        </Link>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>


                                                <div className="tft_fee" >
                                                    <div className="tft_fee_title" >
                                                        <i className="ri-water-percent-line"></i>
                                                        <span>Fee: Maker: {CoinDetails?.maker_fee}% | Taker: {CoinDetails?.taker_fee}% </span>
                                                        <div className="hot_hotTag__YZlkh hotTag">HOT</div>
                                                    </div>
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className="col-12 col-sm-6 col-lg-6 col-xl-9 col-xxl-9" >
                            <div className="trade_card mb-0">
                                <div className="treade_card_header d-flex-between trade_tabs w-100 align-items-center buy_sell_cards p-0" >
                                    <ul className="nav custom-tabs order_his_tabs ">
                                        <li className="buysell-tab"><a className="active" data-bs-toggle="tab" href="#open_orders" onClick={() => { setorderType('All'); setTradeHistory([]); setCheckVisibleTable({ OrderHistory: false, TrdeHistory: false, }); }}>Open Orders</a></li>
                                        <li className="buysell-tab"><a data-bs-toggle="tab" href="#past_orders" onClick={() => { token && handlePastOrder(baseCurId, quoteCurId); setorderType('All'); setTradeHistory([]) }}>Order History </a></li>
                                        <li className="buysell-tab"><a data-bs-toggle="tab" href="#trade_orders" onClick={() => { token && handleTradeHistory(baseCurId, quoteCurId); setorderType('All') }}>Trade History </a></li>

                                    </ul>
                                    {tradeHistory?.length > 0 &&
                                        <div className=" num-div" >
                                            <select className="form-select num-select p-0 input-select" onChange={exportToPDF}>
                                                <option hidden>Download Trade History</option>
                                                <option value="PDF">PDF</option>
                                            </select>
                                        </div>}
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active  px-0" id="open_orders">
                                        <div className="scroll_y orders_scroll_y" >
                                            <div className='table-responsive'>
                                                <table className="table table_home mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th> Date</th>
                                                            <th>Trading Pair</th>
                                                            <th> Type</th>
                                                            <th><div className="spot_limit num-div justify-content-start">
                                                                <select className='form-select num-select p-0 input-select' name="" value={orderType} onChange={(e) => { setorderType(e.target.value) }}>
                                                                    <option value="All">All</option>
                                                                    <option value="BUY">Buy</option>
                                                                    <option value="SELL">Sell</option>
                                                                </select>
                                                            </div></th>
                                                            <th> Price</th>
                                                            <th>Amount</th>
                                                            <th>Remaining</th>
                                                            <th>Filled</th>
                                                            <th>Total</th>
                                                            <th className='text-end' > Action </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {openOrders?.length > 0 ? openOrders?.map((item, index) =>
                                                            (orderType === item?.side || orderType === 'All') &&
                                                            <tr key={index}>
                                                                        <td> <div className='td_div d-block' >
                                                                        {item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY")} <br/> <small className='d-block' >{moment(item.updatedAt).format("h:mm:ss A")}</small>
                                                                </div> </td>
                                                                <td >{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                                <td >{item?.order_type}</td>
                                                                <td className={item?.side === 'BUY' ? 'text-success' : 'text-danger'}>{item?.side}</td>
                                                                <td>{parseFloat(item?.price?.toFixed(8))}</td>
                                                                <td>{parseFloat(item?.quantity?.toFixed(8))}</td>
                                                                <td>{parseFloat(item?.remaining?.toFixed(8))}</td>
                                                                <td>{parseFloat(item?.filled?.toFixed(8))}</td>
                                                                <td>{parseFloat((item?.price * item?.remaining)?.toFixed(8))}</td>
                                                                <td className='text-end' >
                                                                    <a href='#/' title="Delete Order" className="btn-link" onClick={() => { cancelOrder(item?._id) }}><i className="ri-delete-bin-6-line pr-0"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ) : <tr rowSpan="8">
                                                            <td colSpan="12">
                                                                <p className="text-center no-data h-100 mb-0" >
                                                                    <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                                    No data Available
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade show  px-0 " id="past_orders">
                                        <div className="scroll_y orders_scroll_y">
                                            <div className='table-responsive'>
                                                <table className="table table_home mb-0 ">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Trading Pair</th>
                                                            <th>Order Type</th>
                                                            <th><div className="spot_limit num-div justify-content-start">
                                                                <select className=" form-select num-select p-0 input-select" name="" value={orderType} onChange={(e) => { setorderType(e.target.value) }}>
                                                                    <option value="All">All</option>
                                                                    <option value="BUY">Buy</option>
                                                                    <option value="SELL">Sell</option>
                                                                </select>
                                                            </div></th>
                                                            <th>Executed</th>
                                                            <th>Price</th>
                                                            <th>Quantity</th>
                                                            <th>Total</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pastOrders?.length > 0 ? pastOrders?.map((item, index) =>
                                                            (orderType === item?.side || orderType === 'All') &&
                                                            <tr key={index}>
                                                                <td> <div className='td_div d-block' >
                                                                {item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY")} <br/> <small className='d-block' >{moment(item.updatedAt).format("h:mm:ss A")}</small>
                                                                </div> </td>
                                                                <td >{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                                <td>{item?.order_type}</td>
                                                                <td className={item?.side === 'BUY' ? 'text-success' : 'text-danger'}>{item?.side}</td>
                                                                <td>{parseFloat(item?.filled?.toFixed(8))}</td>
                                                                <td>{parseFloat(item?.price?.toFixed(8))}</td>
                                                                <td>{parseFloat(item?.quantity?.toFixed(8))}</td>
                                                                <td>{parseFloat((item?.quantity * item?.price)?.toFixed(8))}</td>
                                                                <td>{item?.status}</td>
                                                            </tr>
                                                        ) : <tr rowSpan="5">
                                                            <td colSpan="12">
                                                                <p className="text-center no-data h-100 mb-0" >
                                                                    <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                                    No data Available
                                                                </p>
                                                            </td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show  px-0 " id="trade_orders">
                                        <div className="scroll_y orders_scroll_y">
                                            <div className='table-responsive'>
                                                <table className="table table_home mb-0 ">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Trading Pair</th>
                                                            <th>Order Type</th>
                                                            <th><div className="spot_limit num-div justify-content-start">
                                                                <select className=" form-select num-select p-0 input-select" name="" value={orderType} onChange={(e) => { setorderType(e.target.value) }}>
                                                                    <option value="All">All</option>
                                                                    <option value="BUY">Buy</option>
                                                                    <option value="SELL">Sell</option>
                                                                </select>
                                                            </div></th>
                                                            <th>Price</th>
                                                            <th>Executed</th>
                                                            <th>Fee</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tradeHistory?.length > 0 ? tradeHistory?.map((item, index) =>
                                                            (orderType === item?.side || orderType === 'All') &&
                                                            <tr key={index}>
                                                                <td> <div className='td_div d-block' >
                                                                {item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY")} <br/> <small className='d-block' >{moment(item.updatedAt).format("h:mm:ss A")}</small>
                                                                </div> </td>
                                                                <td >{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                                <td>{item?.order_type}</td>
                                                                <td className={item?.side === 'BUY' ? 'text-success' : 'text-danger'}>{item?.side}</td>
                                                                <td>{parseFloat(item?.price?.toFixed(8))}</td>
                                                                <td>{parseFloat(item?.quantity?.toFixed(8))}</td>
                                                                <td>{parseFloat(item?.fee?.toFixed(8))}</td>
                                                                <td>{parseFloat((item?.quantity * item?.price)?.toFixed(8))}</td>
                                                            </tr>
                                                        ) : <tr rowSpan="5">
                                                            <td colSpan="12">
                                                                <p className="text-center no-data h-100 mb-0" >
                                                                    <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                                    No data Available
                                                                </p>
                                                            </td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-8 col-lg-6 col-xl-3 col-xxl-3">
                            <div className="trade_card" >
                                <div className="treade_card_header"><div className="card_header_title active">Market Traders</div></div>
                                <div className="price_card_head">
                                    <div>Price({SelectedCoin?.quote_currency})</div>
                                    <div>Quantity({SelectedCoin?.base_currency})</div>
                                    <div>Time
                                    </div>
                                </div>
                                <div className="price_card_body scroll_y scroll_y_mt" >
                                    {(RecentTrade?.length > 0 && !loader) ?
                                        RecentTrade?.map((item, index) =>
                                            <div key={index} className="price_item_value "><span className={item?.side === "BUY" ? "text-success d-flex align-items-center" : "text-danger d-flex align-items-center"}> {parseFloat((item?.price)?.toFixed(8))}</span><span >{(item?.quantity)?.toFixed(5)}</span><span > {item.updatedAt && moment(item.updatedAt).format("LTS")}</span></div>
                                        ) : loader ? <div className="text-center no-data h-100 mb-0" >
                                            <div className="loading-wave">
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div></div> : <p className="text-center no-data h-100 mb-0" >
                                            <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                            No data Available
                                        </p>}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="modal fade" id="BuyConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <form className="modal-content">
                        <div className="modal-header flex-column p-0 no-border">
                            {/* <h3 className="modal-title" id="placeBitLaebl"> Do you want to place this order?</h3> */}
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                                className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body p-md-4 pt-md-5 p-2 text-center">
                            <h2 className="al_text" > Do you want to place this order?</h2>
                            <div className='row justify-content-center '>
                                <div className="col-md-10   " >
                                    <button type="button" className="next_btn btn-success m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => handleOrderPlace(infoPlaceOrder, buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice, buyamount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'BUY')}> <span>Buy</span>
                                    </button>
                                </div>
                                <div className="col-md-10 my-3 " >
                                    <button type="button" className="next_btn btn-glass m-auto w-100 btn btn-block" data-bs-dismiss="modal"> <span>Cancel</span>
                                    </button>
                                </div>
                                <div className="col-md-10  " >
                                    <button type="button" className="next_btn btn-link skip-btn m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => { skipModel(true) }}> <span>Skip </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal fade" id="SellConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <form className="modal-content">
                        <div className="modal-header flex-column p-0 no-border">
                            {/* <h3 className="modal-title" id="placeBitLaebl"> Do you want to place this order?</h3> */}
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                                className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body p-md-4 pt-md-5 p-2 text-center">
                            <h2 className='al_text' > Do you want to place this order?</h2>
                            <div className='row justify-content-center '>
                                <div className="col-md-10   " >
                                    <button type="button" className="next_btn btn-danger m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => handleOrderPlace(infoPlaceOrder, sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : sellPrice, sellAmount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'SELL')} > <span>Sell</span>
                                    </button>
                                </div>
                                <div className="col-md-10 my-3  " >
                                    <button data-bs-dismiss="modal" type="button" className="next_btn btn-glass m-auto w-100 btn btn-block"> <span>Cancel</span>
                                    </button>
                                </div>
                                <div className="col-md-10  " >
                                    <button type="button" className="next_btn btn-link skip-btn m-auto w-100 btn btn-block" data-bs-dismiss="modal" onClick={() => { skipModel(true) }}> <span>Skip  </span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Trade
